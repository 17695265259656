import { newModel, MemoryAdapter } from "casbin.js";

export const model = newModel(`
[request_definition]
r = sub, obj, act

[policy_definition]
p = sub, obj, act

[role_definition]
g = _, _

[policy_effect]
e = some(where (p.eft == allow))

[matchers]
m = g(r.sub, p.sub) && keyMatch(r.obj, p.obj) && regexMatch(r.act, p.act)
`);

export const adapter = new MemoryAdapter(`
p, manager, bnpl`+process.env.BNPL_STAGE+`_seller, list

p, manager, bnpl`+process.env.BNPL_STAGE+`_platform, list

p, manager, bnpl`+process.env.BNPL_STAGE+`_offer, list
p, manager, bnpl`+process.env.BNPL_STAGE+`_offer, edit
p, manager, bnpl`+process.env.BNPL_STAGE+`_offer, create

p, manager, bnpl`+process.env.BNPL_STAGE+`_buyer, list

p, manager, bnpl`+process.env.BNPL_STAGE+`_onboarding, list

p, manager, bnpl`+process.env.BNPL_STAGE+`_credit_line, list

p, manager, bnpl`+process.env.BNPL_STAGE+`_collection, list
p, manager, bnpl`+process.env.BNPL_STAGE+`_collection, create

p, manager, bnpl`+process.env.BNPL_STAGE+`_checkout, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_buyer, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_seller, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_onboarding, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_credit_line, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_collection, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_checkout, list

p, partner, bnpl`+process.env.BNPL_STAGE+`_offer, list
p, partner, bnpl`+process.env.BNPL_STAGE+`_offer, edit
p, manager, bnpl`+process.env.BNPL_STAGE+`_payin_provider, list
p, manager, bnpl`+process.env.BNPL_STAGE+`_checkouts_collection_view, list
`);
