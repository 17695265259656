import { Table, TextField, Typography, UrlField } from "@pankod/refine-antd";
import { emailRegex, urlRegex } from "./common";

export interface ExpandedOptions {
    jsonField?: string
    includeFields?: string[];
    excludeFields?: string[];
}



export const ExpandedRowRenderFields = (options?: ExpandedOptions) => (record: any, index: number, indent: number, expanded: boolean) => {

    const { includeFields, excludeFields, jsonField = 'data' } = options || {}
    const data: { [k: string]: any } = record[jsonField]

    let dataMod = { ...data }

    if (includeFields) {
        let dataTmp: any = {}
        includeFields.forEach(f => dataTmp[f] = dataMod[f])
        dataMod = dataTmp
    }

    if (excludeFields) {
        excludeFields.forEach(f => delete dataMod[f])
    }

    let rows = Object.keys(dataMod).map((k, i) => ({ key: i, field: k, value: dataMod[k] }))

    return <Table dataSource={rows} pagination={false}>
        <Table.Column
            dataIndex="field"
            title="Field"
            render={(value) => <TextField value={value} />}
        />
        <Table.Column
            dataIndex="value"
            title="Value"
            render={(value) => {
                if (emailRegex.test(value))
                    return <Typography.Link href={`mailto:${value}`} target="_blank">
                        {value}
                    </Typography.Link>
                else if (urlRegex.test(value))
                    return <UrlField value={value} />
                else
                    return <TextField value={value} />
            }}
        />
    </Table>;
}