import dataProvider, {GenerateSortingType, HasuraFilterCondition, HasuraSortingType} from "@pankod/refine-hasura";
import {GraphQLClient} from "graphql-request";
import * as gql from "gql-query-builder";
import {CrudFilters, CrudOperators, CrudSorting} from "@pankod/refine-core";
import moment from "moment";
//import jwt from "jsonwebtoken"

const {REACT_APP_API_URL, REACT_APP_GRAPHQL_SUFFIX} = process.env
const API_URL = `${REACT_APP_API_URL}${REACT_APP_GRAPHQL_SUFFIX}` || 'http://127.0.0.1:8080';

export const client = new GraphQLClient(API_URL);

const setHeaders = (client: GraphQLClient) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return client
    }

    let defaultRole = "";
    if (token && token !== '') {
        try {
            const {payload} = jwtDecode(token)
            // console.log(header, payload)
            const claims = JSON.parse(payload['https://hasura.io/jwt/claims'])
            //console.log("Claims ", claims)
            defaultRole = claims['x-hasura-default-role']
        } catch (err) {
            console.log(err)
        }
    }

    const {payload} = jwtDecode(token)
    // console.log(header, payload)
    if (payload['custom:PlatformId'] !== undefined) {
        const partner_id = payload['custom:PlatformId']
        client.setHeader('x-hasura-platform-id', partner_id)
    }
    client.setHeader('authorization', 'Bearer ' + token)
    client.setHeader('x-hasura-role', defaultRole)

    return client

    // TODO migrate graphql to rest calls
    // if (payload['custom:JWTSecret'] !== undefined) {
    //     const r2payload = {
    //     }
    //     const r2Token = jwt.sign(r2payload, payload['custom:JWTSecret'], {
    //         expiresIn: "2h",
    //         header: {
    //             "typ": "JWT",
    //             "alg": "HS256",
    //             "kid": payload['custom:kid'],
    //         }
    //     });
    //     localStorage.setItem("r2token", r2Token);
    // }

}


export const gqlDataProvider = {
    ...dataProvider(client),
    getOne: async ({resource, id, metaData}: any) => {
        const operation = `${metaData?.operation ?? resource}_by_pk`;
        const newKeyPrefix = resource.split("_")
        const idKey = `${metaData?.operation ?? newKeyPrefix[newKeyPrefix.length - 1]}_id`

        const {query, variables} = gql.query({
            operation,
            variables: {
                [idKey]: {value: id, type: "uuid", required: true},
                ...metaData?.variables,
            },
            fields: metaData?.fields,
        });

        const clientR2 = setHeaders(client)
        const response = await clientR2.request(query, variables);

        return {
            data: response[operation],
        };
    },
    getMany: async ({resource, ids, metaData}: any) => {
        const operation = metaData?.operation ?? resource;

        const {query, variables} = gql.query({
            operation,
            fields: metaData?.fields,
            variables: metaData?.variables ?? {
                where: {
                    type: `${operation}_bool_exp`,
                    value: {
                        id: {
                            _in: ids,
                        },
                    },
                },
            },
        });

        const clientR2 = setHeaders(client)
        const response = await clientR2.request(query, variables);

        return {
            data: response[operation],
        };
    },
    getList: async ({resource, sort, filters, pagination, metaData}: any) => {
        const current = pagination?.current ?? 1;
        const limit = pagination?.pageSize || 10;
        const offset = (current - 1) * limit;

        const hasuraSorting = generateSorting(sort);
        const hasuraFilters = generateFilters(filters);

        const operation = metaData?.operation ?? resource;

        const aggregateOperation = `${operation}_aggregate`;

        const hasuraSortingType = `[${operation}_order_by!]`;
        const hasuraFiltersType = `${operation}_bool_exp`;

        const {query, variables} = gql.query([
            {
                operation,
                fields: metaData?.fields,
                variables: {
                    limit,
                    offset,
                    ...(hasuraSorting && {
                        order_by: {
                            value: hasuraSorting,
                            type: hasuraSortingType,
                        },
                    }),
                    ...(hasuraFilters && {
                        where: {
                            value: hasuraFilters,
                            type: hasuraFiltersType,
                        },
                    }),
                },
            },
            {
                operation: aggregateOperation,
                fields: [{aggregate: ["count"]}],
                variables: {
                    where: {
                        value: hasuraFilters,
                        type: hasuraFiltersType,
                    },
                },
            },
        ]);

        const clientR2 = setHeaders(client)
        const response = await clientR2.request(query, variables);

        return {
            data: response[operation],
            total: response[aggregateOperation].aggregate.count,
        };
    },
    create: async ({resource, variables, metaData}: any) => {
        const operation = metaData?.operation ?? resource;

        const insertOperation = `insert_${operation}_one`;
        const insertType = `${operation}_insert_input`;

        const {query, variables: gqlVariables} = gql.mutation({
            operation: insertOperation,
            variables: {
                object: {
                    type: insertType,
                    value: variables,
                    required: true,
                },
            },
            fields: metaData?.fields ?? [...Object.keys(variables)],
        });

        const clientR2 = setHeaders(client)
        const response = await clientR2.request(query, gqlVariables);

        return {
            data: response[insertOperation],
        };
    },
    update: async ({resource, id, variables, metaData}: any) => {
        const operation = metaData?.operation ?? resource;

        const updateOperation = `update_${operation}_by_pk`;

        const pkColumnsType = `${operation}_pk_columns_input`;
        const setInputType = `${operation}_set_input`;
        const newKeyPrefix = resource.split("_")
        const idKey = `${metaData?.operation ?? newKeyPrefix[newKeyPrefix.length - 1]}_id`

        const {query, variables: gqlVariables} = gql.mutation({
            operation: updateOperation,
            variables: {
                pk_columns: {
                    type: pkColumnsType,
                    value: {
                        [idKey]: id,
                    },
                    required: true,
                },
                _set: {
                    type: setInputType,
                    value: variables,
                    required: true,
                },
            },
            fields: metaData?.fields ?? [[idKey]],
        });

        const clientR2 = setHeaders(client)
        const response = await clientR2.request(query, gqlVariables);

        return {
            data: response[updateOperation],
        };
    }
}

function jwtDecode(t: string) {
    let token = {} as any;
    token.raw = t;

    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return (token)
}

export const generateSorting: GenerateSortingType = (sorting?: CrudSorting) => {
    if (!sorting) {
        return undefined;
    }

    let sortingQueryResult: Record<string, "asc" | "desc"> = {};

    sorting.forEach((sortItem) => {
        if (typeof sortItem === "object" && sortItem.field.includes(",")) {
            const query = `{${sortItem.field.split(",").map((field, index) => index < sortItem.field.split(",").length - 1 ? `\"${field}\": {` : `\"${field}\":\"${sortItem.order}\"${sortItem.field.split(",").map((_, index)=> index < sortItem.field.split(",").length - 1 ? "}" : "")}`)}}`.replaceAll(",", "",)
            sortingQueryResult = JSON.parse(query)
        } else {
            sortingQueryResult[sortItem.field] = sortItem.order;
        }
    });

    return sortingQueryResult as HasuraSortingType;
};

export const generateFilters: any = (filters?: CrudFilters) => {
    if (!filters) {
        return undefined;
    }

    let resultFilter: any = {};

    filters.map((filter: any) => {
            if (["created_at", "updated_at", "checkout_time"].includes(filter.field)) {
                const operator = [hasuraFilters["gte"], hasuraFilters["lte"]]
                if (!operator) {
                    throw new Error(`Operator ${filter.operator} is not supported`);
                }
                if (typeof operator === "object" && operator.length > 0) {
                    if (typeof filter.value === "object" && filter.value.length === 2 && typeof filter.value[0] === "object" && typeof filter.value[1] === "object") {
                        const values = [];
                        values[0] = moment(new Date(filter.value[0])).format("YYYY-MM-DD")
                        values[1] = moment(new Date(filter.value[1])).format("YYYY-MM-DD")
                        const query = `{\"${filter.field}\": {\"${operator[0]}\":\"${values[0]}\",\"${operator[1]}\":\"${values[1]}\"}}`
                        resultFilter = JSON.parse(query);
                    }
                }
            } else {
                // @ts-ignore
                const operator: any = hasuraFilters[filter.operator];
                if (!operator) {
                    throw new Error(`Operator ${filter.operator} is not supported`);
                }
                if (filter.operator !== "or") {
                    resultFilter[filter.field] = {};
                    if (typeof (filter.field as any) === "object" && filter.field?.length > 0) {
                        const query = `{${(filter.field as any).map((value: string) => `\"${value}\": {`)}\"${operator}\":\"${filter.value}\"}${(filter.field as any).map((_: string, i: number) => i < filter.field.length - 1 ? "}" : "")}}`.replaceAll(",", "",)
                        resultFilter = JSON.parse(query);
                    } else if (filter.field.includes(".")) {
                        const values = filter.field.split(".");
                        const query = `{${values.map((value: string) => `\"${value}\": {`)}\"${operator}\":\"${filter.value}\"}${values.map((_: string, i: number) => i < values.length - 1 ? "}" : "")}}`.replaceAll(",", "",)
                        resultFilter = JSON.parse(query);
                    } else {
                        resultFilter[filter.field][operator] = filter.value;
                    }
                } else {
                    const orFilter: any = [];

                    filter.value.map((val: any) => {
                        const filterObject: any = {};
                        // @ts-ignore
                        const mapedOperator = hasuraFilters[val.operator];

                        if (!mapedOperator) {
                            throw new Error(
                                `Operator ${val.operator} is not supported`,
                            );
                        }
                        filterObject[val.field] = {};
                        filterObject[val.field][mapedOperator] = val.value;
                        orFilter.push(filterObject);
                    });

                    resultFilter[operator] = orFilter;
                }
            }
        }
    );

    return resultFilter;
};

const hasuraFilters: Record<CrudOperators, HasuraFilterCondition | undefined> =
    {
        eq: "_eq",
        ne: "_neq",
        lt: "_lt",
        gt: "_gt",
        lte: "_lte",
        gte: "_gte",
        in: "_in",
        nin: "_nin",
        contains: "_ilike",
        ncontains: "_nilike",
        containss: "_like",
        ncontainss: "_nlike",
        null: "_is_null",
        or: "_or",
        between: undefined,
        nbetween: undefined,
        nnull: undefined,
    };