import { EmailField, TextField, UrlField } from "@pankod/refine-antd"
import { useTranslate } from "@pankod/refine-core"
import { IBuyer } from "interfaces"
import get from "lodash/get"
import { URL_PREFIX_DOCS_S3 } from "../../../utils/common"


export interface PopupSellerProps {
    buyer: IBuyer | undefined
}

export const PopupBuyerContent = (props: PopupSellerProps) => {
    const { buyer } = props
    const t = useTranslate()

    const fields = [
        //Mex props
        { label: 'RFC', value: 'data.rfc' },

        //common props
        { label: 'Name', value: 'data.name' },
        { label: 'Email', value: 'data.email' },
    ]

    return <div>
        <ul>
            {fields
                .filter(f => !!get(buyer, f.value))
                .map(f => <li><strong>{`${t(f.label)}: `}</strong>{`${get(buyer, f.value)}`}</li>)
            }
            <li>
                <TextField
                    value={`${buyer?.name}`}
                />
            </li>
        </ul>
    </div>
}