import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { upperCase } from "lodash";
import { SVGProps } from "react";
import * as yup from "yup";

const Bucket = 'kalto.r2capital.co'
const region = "us-west-2"
const client = new S3Client({
    region,
    credentials: {
        accessKeyId: process.env.AWS_ACCESS_KEY_ID || '',
        secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || '',
    }
})

export async function uploadFile(path: string, fileStream: ReadableStream) {
    try {
        const parallelUploads3 = new Upload({
            client,
            params: {
                Bucket,
                Key: path.replaceAll(" ",""),
                Body: fileStream,
            },
            // tags: [
            //     /*...*/
            // ], // optional tags
            queueSize: 4, // optional concurrency configuration
            partSize: 1024 * 1024 * 10, // optional size of each part, in bytes, at least 5MB
            leavePartsOnError: false, // optional manually handle dropped parts
        });

        parallelUploads3.on("httpUploadProgress", (progress: any) => {
            console.log(progress);
        });

        const out = await parallelUploads3.done();
        // console.log(out)
        return out
    } catch (err) {
        console.log("Error creating the file: ", err);
        throw err
    }
}

export const uploadFileHandler = (rfc: string, filename: string) => async (file: File) => {
    if (!file) {
        console.log("file not existent")
        return
    }

    if (!rfc && rfc === '') {
        console.log("RFC not set")
        return { Location: null }
    }

    rfc = upperCase(rfc.replaceAll(" ",""))
    const ext = file.name.split('.').slice(-1)[0].toLowerCase()
    const out: any = await uploadFile(`kyc/${rfc}/${filename}.${ext}`, file.stream())

    const path = `https://s3.${region}.amazonaws.com/${out.Bucket}/${out.Key}`
    console.log(`file uploaded successfully:  ${path}`)

    return {
        ...out,
        // https://s3.us-west-2.amazonaws.com/kalto.r2capital.co/kyc/AARJ770221E86/INE_back.jpg
        Location: path,
    }
}


export function LoadIcon(props: SVGProps<SVGSVGElement>) {
    return <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
}

export const UploadFileValidation = yup.mixed()
    .test('required', "Debe subirse un archivo", (value: File) => {
        // console.log("value", value)
        return value && !!value.size
    })
    .test("fileSize", "El archivo es muy grande (max 10Mb)", (value: File, context) => {
        return value && value.size <= 1024 * 1024 * 11; //Mb
    })
    .test("type", "Solo se soportan archivos .png, .jpg, .jpeg, o .pdf", function (value: File) {
        return value && ['image/png', 'image/jpeg', 'application/pdf'].includes(value.type)
    })