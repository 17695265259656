import { notificationProvider } from "@pankod/refine-antd";
import { Refine } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router";
import "./styles/antd.less";
import "./styles/main.css";

import { useTranslation } from "react-i18next";
import { accessProvider } from "./accessProvider";
import { authProvider } from "./authProvider";
import {
  Footer, Header, Layout,
  OffLayoutArea, Sider, Title
} from "./components/layout";
import { gqlDataProvider } from "./dataProvider";
import buyers from "./pages/buyers";
import checkout from "./pages/checkout";
import collections from "./pages/collections";
import { FormPage } from "./pages/form";
import lines from "./pages/lines";
import { Login } from "./pages/login";
import offers from "./pages/offers";
import onboarding from "./pages/onboarding";
import platforms from "./pages/platforms";
import payin_provider from "./pages/payin_provider";
import seller from "./pages/sellers";
import checkouts_collection_view from "./pages/checkouts_collection_view";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      routerProvider={{
        ...routerProvider,
        routes: [{
          component: FormPage,
          path: '/form',
          exact: true,
        }]
      }}
      notificationProvider={notificationProvider}
      dataProvider={gqlDataProvider}
      authProvider={authProvider}
      accessControlProvider={accessProvider(authProvider)}
      LoginPage={Login}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
      resources={[
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_platform",
          options: { label: 'Platforms', route: 'platforms' },
          ...platforms,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_seller",
          options: { label: 'Sellers', route: 'sellers' },
          ...seller,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_buyer",
          options: { label: 'Buyers', route: 'buyers' },
          ...buyers,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_offer",
          options: { label: 'Offers', route: 'offers' },
          ...offers,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_credit_line",
          options: { label: 'Credit Line', route: 'lines' },
          ...lines,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_onboarding",
          options: { label: 'Onboarding', route: 'onboarding' },
          ...onboarding,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_checkout",
          options: { label: 'Checkout', route: 'checkout' },
          ...checkout,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_collection",
          options: { label: 'Collections', route: 'collections' },
          ...collections,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_payin_provider",
          options: { label: 'Payment Links', route: 'payment_links' },
          ...payin_provider,
        },
        {
          name: "bnpl"+process.env.BNPL_STAGE+"_checkouts_collection_view",
          options: { label: 'Checkouts Collection View', route: 'checkouts_collection_view' },
          ...checkouts_collection_view,
        },
      ]}
    />
  );
}

export default App;
