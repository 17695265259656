import {
    Button, DatePicker,
    FilterDropdown,
    FilterDropdownProps, Input,
    List,
    Space,
    Table,
    TextField,
    UrlField,
    useTable
} from "@pankod/refine-antd";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ISeller } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { exportToExcel } from "../../utils/common";
import generateReport, { downloadFile } from "./report";
const { RangePicker } = DatePicker;

export const SellerList: React.FC = () => {
    const { tableProps, sorter } = useTable<ISeller>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "seller_id",
                "name",
                "created_at",
                "extra_data",
                "external_id",
                "website_url",
            ],
        },
    });


    const downloadReports = async (record: ISeller) => {
        //console.log(record.seller_id)
        const data = await generateReport(record.seller_id)
        const date = dayjs().format('YYYY-MM-DD-HH-mm-ss')
        downloadFile(`report-transactions-${date}.csv`, data.trasactions)
        downloadFile(`report-transactions_extra-${date}.csv`, data.trasactions_2)
        downloadFile(`report-balances-${date}.csv`, data.balances)
        downloadFile(`report-balances_extra-${date}.csv`, data.balances_2)
    }

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                ["seller_id", "Id"],
                ["name", "Name"],
                ["created_at", "Created at"],
                ["website_url", "Webpage"],
                ["external_id", "External Id"]
            ]), `BNPL_SellerList`)} />
            <Table
                {...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="seller_id"
                expandable={{ expandedRowRender: ExpandedRowRenderFields() }}
            >
                <Table.Column dataIndex="seller_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column dataIndex="name" title="name"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        try {
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                        } catch(err) {
                            console.log(err)
                            return (<p>No data</p>)
                        }
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="website_url"
                    title="Webpage"
                    render={(value) => <UrlField value={value} />}
                />
                <Table.Column
                    dataIndex="external_id"
                    title="External ID"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column<ISeller>
                    title="Report"
                    dataIndex="actions"
                    key="actions"
                    render={(_text, record) => {
                        return (
                            <Space>

                                <Button
                                    size="small"
                                    onClick={() => downloadReports(record)}
                                >
                                    Generate
                                </Button>
                            </Space>
                        );
                    }}
                />
            </Table>
        </List>
    );
};