import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";
import { delay } from "../../utils/common";
import { IPersonaForm } from "./types";
import { uploadFileHandler, UploadFileValidation } from "./utils";

export const SelectForm = () => {
    const { register } = useFormContext();

    return <fieldset className="field-group w-full">
        <h2 className="section-label">Tu empresa está registrada como:</h2>
        <label className="label label-outline my-6">
            <input
                type="radio"
                value="NATURAL"
                className="radio"
                {...register("persona", { required: true })}
            />
            <span className="label-text">Persona Fisica</span>
        </label>
        <label className="label label-outline my-6">
            <input
                type="radio"
                value="LEGAL"
                className="radio"
                {...register("persona", { required: true })}
            />
            <span className="label-text">Persona Moral</span>
        </label>
    </fieldset>
}

export const SelectCredential = (props: { moral?: boolean }) => {
    const { moral = false } = props
    const { register, watch, setValue, formState: { errors } } = useFormContext<IPersonaForm>();
    const _watchCredential = watch("credential");

    const [changeFormType, setChangeFormType] = useState<{ form: string | null; visible: boolean }>({ visible: false, form: null })

    useEffect(() => {
        const f = async () => {
            setChangeFormType({ form: (changeFormType.form || 'NATURAL'), visible: false })
            await delay(200)
            setChangeFormType({ visible: true, form: _watchCredential })
        }
        f()
    }, [_watchCredential, changeFormType.form])

    return <>
        <fieldset>
            <h2 className="section-label">Selecciona el documento de identificación  que usarás para realizar tu solicitud:</h2>
            <label className="form-control label label-outline">
                <input
                    type="radio"
                    value="ine"
                    className="radio"
                    {...register("credential", { required: true })}
                />
                <span className="label-text">Credencial INE</span>
            </label>
            <label className="form-control label label-outline">
                <input
                    type="radio"
                    value="passport"
                    className="radio"
                    {...register("credential", { required: true })}
                />
                <span className="label-text">Pasaporte</span>
            </label>
        </fieldset>
        {
            changeFormType.form != null &&
            <fieldset className={`w-full flex flex-col items-center transition-opacity duration-500 ${changeFormType.visible ? 'opacity-1' : 'opacity-0'}`}>
                {changeFormType.form === "ine" && !moral && <>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE frontal a color (vigente y legible):</label>
                        <FileUpload name='ine_front' filenamePrefix={'INE_front'} optional='false' />
                    </div>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE posterior a color (vigente y legible):</label>
                        <FileUpload name='ine_back' filenamePrefix={'INE_back'} optional='false' />
                    </div>
                </>
                }
                {changeFormType.form === "ine" && moral && <>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE frontal a color (vigente y legible) de Representante Legal principal:</label>
                        <FileUpload name='ine_front' filenamePrefix={'INE_representante_legal_front'} optional='false' />
                    </div>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE posterior a color (vigente y legible) de Representante Legal principal:</label>
                        <FileUpload name='ine_back' filenamePrefix={'INE_representante_legal_back'} optional='false' />
                    </div>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE frontal a color (vigente y legible) de Representante Legal suplente (cuando se tengan poderes mancomunados):</label>
                        <FileUpload name='ine_front_comp' filenamePrefix={'INE_representante_legal_front_comp'} optional='true' />
                    </div>
                    <div className="form-control w-full">
                        <label className="label label-text" htmlFor="old_time">Adjuntar INE posterior a color (vigente y legible) de Representante Legal suplente (cuando se tengan poderes mancomunados):</label>
                        <FileUpload name='ine_back_comp' filenamePrefix={'INE_representante_legal_back_comp'} optional='true' />
                    </div>
                </>
                }
                {changeFormType.form === "passport" &&
                    <div className="form-control w-full">
                        <h3 className="font-bold">Adjuntar Pasaporte (vigente y legible):</h3>
                        <FileUpload name='passport' filenamePrefix='pasaporte_representante_legal' optional='false' />
                    </div>
                }
            </fieldset>
        }
    </>
}



export interface FileUploadProps {
    name: keyof IPersonaForm;
    filenamePrefix: string;
    optional: string;
}

function obtainDataRegister(optional: string) {
    if (optional === 'false') {
        return {
            deps: ['rfc'],
            required: "el archivo es obligatorio",
        }
    } else {
        return {
            deps: ['rfc'],
        }
    }

}

export const FileUpload = (props: FileUploadProps) => {
    const { name, filenamePrefix, optional } = props
    const { register, watch, setValue, formState: { errors }, setError } = useFormContext<IPersonaForm>();
    const watchRFC = watch('rfc')
    const registeredInput = register(name, obtainDataRegister(optional))

    return <>
        <input
            type="file"
            className="input input-bordered input-file w-full"
            onBlur={registeredInput.onBlur}
            // ref={registeredInput.ref}
            onChange={async (e) => {
                try {
                    const file = e.target?.files?.item(0) as File
                    const value = await UploadFileValidation.validate(file).catch(function (err: any) {
                        // console.log(JSON.stringify(err))
                        setError(name, { type: 'custom', message: `${err.name}: ${err.errors[0]}` })
                        // err.name; // => 'ValidationError'
                        // err.errors; // => [{ key: 'field_too_short', values: { min: 18 } }]
                    })
                    if (value) {
                        if (watchRFC) {
                            setValue(name,
                                (await uploadFileHandler(watchRFC.replaceAll(" ",""), filenamePrefix)(file)).Location)
                            setError(name, {})
                        }
                        else {
                            setError(name, { type: 'custom', message: 'el RFC es necesario para subir un archivo' })
                        }
                    }
                } catch (err: any) {
                    setError(name, { type: 'custom', message: err })
                }
            }}
            onClick={(e) => {
                if (!watchRFC) {
                    e.preventDefault()
                    e.stopPropagation()
                    Swal.fire({
                        title: "No detectamos un RFC ingresado",
                        text: "Por favor valida que hayas ingresado correctamente tu RFC",
                        showCancelButton: false,
                        showDenyButton: false,
                    })
                }
            }}
        />
        <span className="label label-text-alt">sube una foto con formato .png, .jpg, .jpeg, o .pdf menor a 10Mb</span>
        {errors[name] && <span className="text-red-600">{errors[name].message}</span>}
    </>
}