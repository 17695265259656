import * as XLSX from "xlsx";
// @ts-ignore
import * as FileSaver from 'file-saver';
import _ from "lodash";
import moment from "moment";

const MapCountries: { [key: string]: string } = {
    mexico: 'rfc',
    //TODO: more countries
}
export const getIdFieldByCountry = (c: string) => {

    return MapCountries[c] || 'id'
}

export const URL_PREFIX_DOCS_S3 = 'https://s3.console.aws.amazon.com/s3/buckets/kalto.r2capital.co?region=us-west-2&prefix=kyc'

export const delay = (time: number) => new Promise(res => setTimeout(res, time));


export const logger = (() => {
    if (process.env.NODE_ENV === 'development') {
        return console
    }
    return {} as typeof console
})()


export const exportToExcel = async (items: any[], dataToExport: Map<string, any>, fileName: string = "file", extraData?: any) => {
    if (items?.length > 0) {
        const data = (items || []).map(row => {
            let item = {
                ...extraData || {}
            };

            dataToExport.forEach((value: any, key: string) => {
                if (typeof value == "object") {
                    item[value.name] = value?.func(row)
                } else if (_.has(row, key)) {
                    item[value] = _.get(row, key)
                }
            })

            return item;
        })

        exportCSV(data, fileName);
    } else {
        console.error(`No pudimos obtener items para exportar: ${JSON.stringify(items)}`)
    }
}

export const exportCSV = (csvData: any[], fileName: string) => {
    let wscols: any[] = [];
    if (csvData.length > 0) {
        Object.keys(csvData[0]).forEach((key: string) => {
            const values = csvData.map((row: any) => {
                if (row[key] && row[key] != undefined && row[key].toString().length > 0) {
                    return row[key].toString().length > key.length ? row[key].toString() : key;
                } else {
                    return key;
                }
            });
            wscols.push({
                wch: values.reduce((a, b) => a.length > b.length ? a : b).length
            });
        });
    }

    const ws = XLSX.utils.json_to_sheet(csvData);
    ws['!cols'] = wscols;
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], {origin: -1});
    ws['A1'].fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFFFFF00'},
        bgColor: {argb: 'FF0000FF'}
    };
    XLSX.utils.format_cell(ws['A1'], {
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        },
        font: {
            bold: true,
            sz: 14
        }
    });
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: "application/octet-stream"});
    const time = moment().format("YYYY-MM-DD HH:mm").replaceAll(" ", "_")
    FileSaver.saveAs(data, `${fileName}${time}.xlsx`);
}