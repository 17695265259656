import { IBuyer } from "interfaces";
import jmespath from 'jmespath';
import moment from 'moment';
import { gqlDataProvider } from "../../dataProvider";

const { Parser, transforms: { unwind } } = require('json2csv');

const round = (num: any) => {
    return +(Math.round(num * 100) / 100);
}

async function generateReport(sellerId: string) {
    const { data } = await gqlDataProvider.getList<IBuyer>({
        resource: 'bnpl' + process.env.BNPL_STAGE + '_buyer',
        filters: [
            {
                field: 'seller_id',
                operator: 'eq',
                value: sellerId,
            }
        ],
        sort: [
            {
                field: 'external_id',
                order: 'desc'
            }
        ],
        pagination: {
            current: 1,
            pageSize: 10000,
        },
        metaData: {
            fields: [
                'extra_data',
                'tax_id_code',
                'buyer_id',
                'name',
                'email',
                'phone_number',
                {
                    onboarding: [
                        'created_at',
                    ]
                },
                {
                    credit_lines: [
                        'status',
                        'credit_line_id',
                        'amount',
                        {
                            v_credit_line_balance: [
                                'remaining_balance',
                                'owed_amount'
                            ]
                        },
                        {
                            checkouts: [
                                'status',
                                'amount',
                                'checkout_time',
                                'term_days',
                                'checkout_id'
                            ]
                        },
                        {
                            collections: [
                                'status',
                                'amount',
                                'collection_time',
                                'checkout_id'
                            ]
                        }
                    ]
                }
            ]
        }
    })

    // console.log(data)
    const outTransactions: any[] = jmespath.search(data, `[*].{
        credit_requested_date: onboarding.created_at,
        rfc: tax_id_code,
        name: name,
        email: email,
        phone: phone_number,
        credit_line_mxn: credit_lines[0].amount,
        status: credit_lines[0].status,
        payments: credit_lines[0].collections[?status!='CANCELED'].{
            amount: amount,
            payment_date: collection_time,
            checkout_id: checkout_id
        },
        sales: credit_lines[0].checkouts[?status!='CANCELED'].{
            checkout_id: checkout_id,
            status: status,
            sale: amount,
            collected_at: checkout_time,
            expire_days: term_days
        }
    }`)

    const filteredOutTransactions = outTransactions
        // .filter((item: { razon_social: any; }) => !!item.razon_social)
        .map((item: { payments: any[]; sales: any[]; }) =>
        ({
            ...item,
            sales: item.sales?.map(s => ({
                ...s,
                date: s.collected_at.split('T')[0],
                expire_date: moment(s.collected_at).add(s.expire_days, 'days').toISOString().split('T')[0],
                payments: item.payments.filter(({ checkout_id }) => checkout_id === s.checkout_id).map((p: any) => ({
                    ...p,
                    payment_date: p.payment_date ? p.payment_date.split('T')[0] : "",
                }))
            })),
            to_expire: round(item.sales ? item.sales.filter(({ status }) => status === 'ACTIVE').reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.sale;
            }, 0) : 0),
            expired: round(item.sales ? item.sales.filter(({ status }) => status === 'OVERDUE').reduce(function (previousValue, currentValue) {
                return previousValue + currentValue.sale;
            }, 0) : 0)
        }))

    // return
    const parserTransactions = new Parser({
        fields: [
            { value: 'rfc', label: 'rfc' },
            { value: 'name', label: 'razon_social' },
            { value: 'email', label: 'email' },
            { value: 'phone', label: 'phone' },
            { value: 'credit_line_mxn', label: 'credit_line_mxn' },
            { value: 'sales.collected_at', label: 'collected_at' },
            { value: 'sales.sale', label: 'sale' },
            { value: 'sales.date', label: 'date' },
            { value: 'sales.expire_date', label: 'expire_date' },
            { value: 'sales.payments.amount', label: 'payment_amount' },
            { value: 'sales.payments.payment_date', label: 'payment_date' },
        ],
        transforms: [unwind({ paths: ['sales', 'sales.payments'] })],
    });
    const parserTransactions_2 = new Parser({
        fields: [
            { value: 'name', label: 'nombre' },
            { value: 'credit_requested_date', label: 'fecha_solicitud_credito' },
            { value: 'credit_line_mxn', label: 'monto_del_credito' },
            { value: 'sales.collected_at', label: 'collected_at' },
            { value: 'sales.sale', label: 'sale' },
            { value: 'sales.date', label: 'date' },
            { value: 'sales.expire_date', label: 'expire_date' },
            { value: 'sales.payments.amount', label: 'payment_amount' },
            { value: 'sales.payments.payment_date', label: 'payment_date' },
            { value: 'to_expire', label: 'saldo_a_vencer' },
            { value: 'expired', label: 'saldo_vencido' },
            { value: 'status', label: 'estado' },
        ],
        transforms: [unwind({ paths: ['sales', 'sales.payments'] })],
    });
    const csvTransactions = parserTransactions.parse(filteredOutTransactions);
    const csvTransactions_2 = parserTransactions_2.parse(filteredOutTransactions);

    const outBalances: any[] = jmespath.search(data, `[*].{
        rfc: tax_id_code,
        razon_social: name,
        balance: credit_lines[0].v_credit_line_balance.remaining_balance,
        owed: credit_lines[0].v_credit_line_balance.owed_amount,
        amount: credit_lines[0].amount
    }`)

    const outBalances_2: any[] = jmespath.search(data, `[*].{
        rfc: tax_id_code,
        razon_social: name,
        importe: credit_lines[0].amount,
        disponible: credit_lines[0].v_credit_line_balance.remaining_balance,
        saldo_vencido: credit_lines[0].v_credit_line_balance.owed_amount,
        sales: credit_lines[0].checkouts[?status!='CANCELED'].{
            status: status,
            sale: amount,
            collected_at: checkout_time,
            expire_days: term_days,
            payments: collections[?status!='CANCELED'].{
                amount: amount,
                payment_date: collection_time
           }
        },
        status: credit_lines[0].status
    }`)

    const filteredBalances = outBalances
        .filter(item => item.balance != null)

    const filteredBalances_2 = outBalances_2
        .filter(item => item.disponible != null).map((item) =>
        ({
            ...item,
            disponible: item.status === 'OVERDUE' ? 0 : item.disponible,
            saldo_por_vencer: round(item.sales ? item.sales.filter((el: { status: string; }) => el.status === 'ACTIVE').reduce(function (previousValue: any, currentValue: { sale: any; }) {
                return previousValue + currentValue.sale;
            }, 0) : 0),
        })).map(({ sales, ...keepAttrs }) => keepAttrs)

    //console.log(filteredBalances_2)

    const parserBalances = new Parser()
    const csvBalances = parserBalances.parse(filteredBalances);
    const parserBalances_2 = new Parser()
    const csvBalances_2 = parserBalances_2.parse(filteredBalances_2);

    // console.log(csv);
    return {
        trasactions: csvTransactions,
        trasactions_2: csvTransactions_2,
        balances: csvBalances,
        balances_2: csvBalances_2,
    }
}

export const downloadFile = (fileName: string, data: string) => {
    const element = document.createElement("a");

    const mimetypes: { [key: string]: string } = {
        'csv': 'text/csv',
        'json': 'application/json'
    }

    const ext = fileName.split('.').slice(-1)[0]
    const file = new Blob([data], { type: mimetypes[ext] });
    element.href = URL.createObjectURL(file);
    element.download = fileName
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

export default generateReport
