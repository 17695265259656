import { ChangeEvent, HTMLAttributes, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";
import { FileUpload, SelectCredential } from "./components";
import { IPersonaForm } from "./types";
import { validationPatterns } from "./validations";

export interface MoralFormProps extends HTMLAttributes<HTMLDivElement> {

}
export const MoralForm = (props: MoralFormProps) => {
    const { register, setValue, watch, formState: { errors } } = useFormContext<IPersonaForm>();
    const watchRFC = watch('rfc')

    const validateRfc = () => {
        if (!validationPatterns.rfc_moral.test(watchRFC)) {
            Swal.fire({
                title: "El RFC ingresado no es válido",
                text: "Por favor valida que hayas ingresado correctamente tu RFC",
                showCancelButton: false,
                showDenyButton: false,
            })
        }
    }

    useEffect(() => {
        if (watchRFC) {
            normalizeRfc(watchRFC)
        }
    }, [watchRFC])
    

    const normalizeRfc = (e : String) => {
        const normalRFC = e.replaceAll(" ", "").toUpperCase()
        setValue('rfc',normalRFC)
    }

    return <div {...props}>
        <fieldset>
            <h2 className="section-label">Ingresa los siguientes datos acerca de la identidad y actividad de tu empresa:</h2>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="RFC Empresa (Registro Federal de Contribuyentes)"
                    className="input input-bordered w-full"
                    {...register("rfc", {
                        required: true,
                        pattern: {
                            value: validationPatterns.rfc_moral,
                            message: 'RFC debe ser válido',
                        }
                    })}
                    onBlur={validateRfc}
                />
                {errors.rfc && <span className="text-red-600">{errors.rfc?.message}</span>}
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Razón Social"
                    className="input input-bordered w-full"
                    {...register("name", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Nombre comercial"
                    className="input input-bordered w-full"
                    {...register("commercial_name", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="email"
                    placeholder="Email de la empresa"
                    className="input input-bordered w-full"
                    {...register("email", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="phone"
                    placeholder="Número de Whatsapp: ej. +52 55 7194 8630"
                    className="input input-bordered w-full"
                    {...register("phone", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Actividad, giro, ocupación o profesión"
                    className="input input-bordered w-full"
                    {...register("activity", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Nombre y apellido del Representante Legal"
                    className="input input-bordered w-full"
                    {...register("legal_rep_name", { required: true })}
                />
            </div>
        </fieldset>
        <SelectCredential moral />
        <h2 className="section-label">Otros documentos:</h2>
        <fieldset>
            <div className="form-control w-full">
                <label className="label label-text">Adjuntar Acta Constitutiva que describa los poderes del o los representantes legales:</label>
                <FileUpload name='acta' filenamePrefix='acta_constitutiva' optional='false' />
            </div>
            <div className="form-control w-full">
                <label className="label label-text">Adjuntar documento con los poderes para actos de administración y dominio (solo en caso de que no se describan en el Acta constitutiva):</label>
                <FileUpload name='acta_comp' filenamePrefix='acta_constitutiva_comp' optional='true' />
            </div>
            <div className="form-control w-full">
                <label className="label label-text">Adjuntar Comprobante de Domicilio (Legible y no mayor a 3 meses):</label>
                <FileUpload name='domicilio' filenamePrefix='comprobante_domicilio_persona_moral' optional="false" />
            </div>
        </fieldset>
        <fieldset>
            <div className="form-control w-full">
                <label className="label label-text">Cantidad de empleados:</label>
                <select
                    className="input input-bordered w-full bg-white"
                    {...register("employees_qty_range", { valueAsNumber: true })}
                >
                    <option value="0">--SELECCIONAR--</option>
                    <option value="1">1-5</option>
                    <option value="2">5-15</option>
                    <option value="3">15-50</option>
                    <option value="4">mas 50</option>
                </select>
            </div>
            <div className="form-control w-full">

                <input
                    type="url"
                    placeholder="Página web o redes sociales (opcional)"
                    className="input input-bordered w-full"
                    {...register("webpage")}
                />
            </div>
        </fieldset>
        <p className="mt-6">
            Esta solicitud deber ser firmada por el representante legal de la persona moral correspondiente y
            en virtud de la confirmación y aceptación de los Términos & Condiciones declara bajo protesta de
            decir verdad que cuenta con las facultades suficientes y necesarias para obligar legalmente a su
            representada con el alcance jurídico de este acto.
        </p>
    </div>
}