import { DownloadOutlined } from "@ant-design/icons";
import {
    Button, DatePicker, FilterDropdown, FilterDropdownProps, Input,
    List,
    NumberField,
    Table,
    TagField,
    TextField,
    useTable
} from "@pankod/refine-antd";
import { ICreditLine } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel } from "../../utils/common";

const { RangePicker } = DatePicker;

export const CollectionList: React.FC = () => {
    const { tableProps, sorter } = useTable<ICreditLine>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "credit_line_id",
                "created_at",
                "updated_at",
                "extra_data",
                "status",
                "currency",
                "amount",
                "buyer_id",
                "remaining_balance",
                "term_days",
                "owed_amount",
                {
                    buyer: [
                        'name',
                        "offer_id",
                        'external_id',
                        {
                            seller: ['seller_id', 'name']
                        }
                    ]
                }
            ],
        },
    });

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                ["credit_line_id", "Id"],
                ["created_at", "Created at"],
                ["updated_at", "Updated at"],
                ["buyer.seller.name", "Seller"],
                ["buyer.external_id", "Buyer"],
                ["buyer.name", "Name"],
                ["currency", "Currency"],
                ["status", "Status"],
                ["term_days", "Term days"],
                ["amount", "Amount"],
                ["owed", "owed_amount"],
                ["available", { name: "Available", func: (row: any) => row.remaining_balance ?? row.amount }],
            ]), `BNPL_LineList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="credit_line_id"
                expandable={{ expandedRowRender: ExpandedRowRenderFields() }}
            >
                <Table.Column dataIndex="credit_line_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICreditLine>
                    dataIndex={["buyer", "seller", "name"]}
                    title="Seller"
                    render={(value, record) => {

                        const seller = record.buyer?.seller
                        return (
                            <TextField value={seller?.name} />
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICreditLine>
                    dataIndex={["buyer", "external_id"]}
                    title="Buyer"
                    render={(value, record) =>
                        <TextField value={value} />
                    }
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICreditLine>
                    dataIndex={["buyer", "name"]}
                    title="Name"
                    render={(value, record) => {
                        return <TextField value={record.buyer?.name} />
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="term_days"
                    title="Term days"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="currency"
                    title="Currency"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="status"
                    title="Status"
                    render={(value) => <TagField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="amount"
                    title="Amount"
                    render={(value) => <NumberField
                        value={value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column<ICreditLine>
                    dataIndex="owed_amount"
                    title="Owed"
                    // render={(value) => <TextField value={value} />}
                    render={(value, record) => <NumberField
                        value={value == null ? 0 : value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column<ICreditLine>
                    dataIndex="remaining_balance"
                    title="Available without taxes"
                    // render={(value) => <TextField value={value} />}
                    render={(value, record) => <NumberField
                        value={value == null ? record.amount : value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
            </Table>
        </List>
    );
};