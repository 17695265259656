import './styles.css'
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { AntdLayout } from "@pankod/refine-antd"
import { useList } from "@pankod/refine-core";
import { IOffer } from "interfaces";
import { FisicaForm } from "./fisicaForm";
import { MoralForm } from "./moralForm";
import { IPersonaForm } from "./types";
import { delay } from "../../utils/common";
import { SelectForm } from "./components";
import axios from "axios"
import { BelvoWidget } from './belvo';
import { LoadIcon } from './utils';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

const AVISO_PRIVACIDAD_URL = 'https://assets.r2capital.co/bnpl/docs/Aviso%20de%20Privacidad%20R2%20MX_VF.pdf'


export const FormPage = () => {
    const [alreadyCompleted, setAlreadyCompleted] = useState(localStorage.getItem("alreadyCompleted") ? true : false)
    const search = useLocation().search;
    const formMethods = useForm<IPersonaForm>();
    const { handleSubmit, watch, setValue, register,
        formState: { isSubmitting, isValidating } } = formMethods
    const [formState, setFormState] = useState<string>('init')
    const [imgSizeClasses, setImgSizeClasses] = useState("opacity-0 h-1")
    // const { widgetState, belvoData } = useBelvo()
    const [changeFormType, setChangeFormType] = useState<{ form: string | null; visible: boolean }>({ visible: false, form: null })

    const _watchFormType = watch("persona")
    const _watchName = watch("name")
    const _watchEmployeeQty = watch("employees_qty_range")
    useEffect(() => {
        if (_watchFormType) {
            const f = async () => {
                setChangeFormType({ form: (changeFormType.form || 'NATURAL'), visible: false })
                await delay(200)
                setChangeFormType({ visible: true, form: _watchFormType })
            }
            f()
        }
    }, [_watchFormType, changeFormType.form])

    const token = new URLSearchParams(search).get('id');
    const clear = new URLSearchParams(search).get('clear') ? true : false;

    if (clear) {
        localStorage.removeItem('alreadyCompleted')
        if (localStorage.getItem("alreadyCompleted")) {
            window.location.reload();
        }
    }

    //only set input when done widget process
    // if (widgetState === 'done')
    // setValue('belvo_data', belvoData)

    const { data } = useList<IOffer>({
        resource: "bnpl" + process.env.BNPL_STAGE + "_offer",
        config: {
            filters: [
                {
                    field: "token",
                    operator: "eq",
                    value: token,
                },
            ],
            pagination: { pageSize: 1 },
        },
        metaData: {
            fields: [
                'extra_data',
                'offer_id',
                {
                    seller: [
                        'platform_id',
                        'name',
                        'seller_id',
                        'external_id',
                        'extra_data',
                        'logo_url',
                        'terms_and_conds'
                    ]
                }
            ]
        }
    });

    if (!token || token === '') {
        Swal.fire({
            text: "Invalid token",
            icon: "error",
        }).then(() => {
            if (typeof window !== "undefined") {
                window.location.replace('https://r2.co')
                return
            }
        });
    }
    //console.log("data", data);
    //console.log(`token: ${token || 'not set'}`)
    register('token', { required: true })
    setValue('token', token!)

    if (data?.data.length === 0) {
        //TODO: implement empty case
        Swal.fire({
            text: "Invalid data readed",
            icon: "error",
        }).then(() => {
            if (typeof window !== "undefined") {
                window.location.replace('https://r2.co')
                return
            }
        });
    }

    const onboarding_id = uuidv4();

    const offer = data?.data[0];
    const seller = offer?.seller

    const onSubmit = async (data: any) => {
        // validate employee qty
        if ((_watchEmployeeQty + "") === "0") {
            Swal.fire({
                title: "Por favor seleccione rango de cantidad de empleados.",
                text:"Con este dato podremos ajustar la mejor oferta!",
                showCancelButton: false,
                showDenyButton: false,
            })
            setFormState('error')
            return
        }

        setFormState('sending')
        try {
            const persona = data.persona
            delete data.persona
            const req = await axios.post(process.env.REACT_APP_ONBOARDING_URL!, {
                created_at: (new Date()).toISOString(),
                person_type: persona,
                data: data,
                token: data.token
            })

            if (req.status === 200) {
                localStorage.setItem("alreadyCompleted", "done")
                setFormState('done')
            } else {
                setFormState('init')

            }
        } catch (err) {
            setFormState('error')
        }
    }

    const loadState = (isSubmitting || isValidating) || (formState === 'sending')
    return <AntdLayout className="!bg-white flex flex-col items-center">
        <div>
            <img
                src={seller?.logo_url}
                onLoad={({ target }) => {
                    const img = target as any
                    const ratio = img.width / img.height
                    // console.log(`img ratio: ${ratio}`)
                    // if (ratio > 0.7 && ratio < 1.3) {
                    //     setImgSizeClasses("r2_img_big")
                    // } else {
                    //     setImgSizeClasses("r2_img_std")
                    // }
                    if (ratio > 0.7 && ratio < 1.3) {
                        setImgSizeClasses("h-20")
                    } else {
                        setImgSizeClasses("h-20")
                    }
                }}
                alt="seller-logo"
                className={`mt-6 md:ml-6 md:absolute md:left-0 md:top-0 transition-opacity ease-in-out delay-150 duration-300 ${imgSizeClasses}`}
            />
        </div>
        <FormProvider {...formMethods}>
            {(formState !== 'done' && !alreadyCompleted) &&
                <form onSubmit={handleSubmit(onSubmit)} className="bg-white lg:w-3/4 m-auto flex items-center flex-col mx-4 md:mx-auto">
                    <div className='md:w-2/3 w-full lg:w-1/2 m-auto flex flex-col items-center justify-center'>
                        <h1 className="text-2xl md:text-4xl font-semibold md:mt-40 my-20 max-w-[500px] self-start">
                            Para <span className="text-primary">activar tu línea de crédito, </span>
                            llena el siguiente formulario acerca de tu
                            identidad y la de tu empresa.
                        </h1>
                        {/* <div className="mb-16">
                        <h1 className="section-header">Identidad</h1>
                        <div className="h-[3px] w-full bg-primary"></div>
                    </div> */}

                        {/* select persona radios */}
                        <SelectForm />

                        {changeFormType.form != null &&
                            <div className={`w-full flex flex-col items-center transition-opacity duration-200 ${changeFormType.visible ? 'opacity-1' : 'opacity-0'}`}>

                                {/* persona forms */}
                                {changeFormType.form === "NATURAL" && <FisicaForm className="w-full" />}
                                {changeFormType.form === "LEGAL" && <MoralForm className="w-full" />}
                                {seller?.extra_data?.underwriting?.belvo_widget?.active &&
                                    <BelvoWidget token={token} onboarding_id={onboarding_id} {...register("belvo_data")} />
                                }
                                {/* Checkboxes */}
                                <fieldset className="form-control w-full">
                                    <label className="cursor-pointer label">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            {...register("check_info", { required: true })}
                                        />
                                        <span className="label-text">Confirmo que la información es válida y que acepto la oferta.</span>
                                    </label>
                                    <label className="cursor-pointer label">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            {...register("check_priv", { required: true })}
                                        />
                                        <span className="label-text">Acepto el <a href={seller?.extra_data?.privacy_notice ? seller?.extra_data?.privacy_notice : AVISO_PRIVACIDAD_URL} target="_blank" rel="noreferrer">Aviso de Privacidad</a></span>
                                    </label>
                                    <label className="cursor-pointer label">
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            {...register("check_terms", { required: true })}
                                        />
                                        <span className="label-text">Acepto los  <a href={seller?.terms_and_conds} target="_blank" rel="noreferrer">Términos & Condiciones</a></span>
                                    </label>
                                </fieldset>

                                {/* submit button */}
                                {/* {!isValid &&
                                    <span className="text-red-600">
                                        Hay un error en el formulario
                                    </span>
                                } */}
                                <button type="submit" className="py-4 px-16 bg-[#0C101B] text-white font-bold my-6 rounded-md flex">
                                    {loadState && <LoadIcon className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />}
                                    {loadState ? 'Enviando...' : 'Solicitar activación de línea'}
                                </button>
                            </div>}
                    </div>
                </form>
            }
            {formState === 'done' &&
                <div>
                    <h1 className="text-2xl md:text-4xl font-semibold md:mt-40 my-20 max-w-[450px] self-start">
                        Listo <span className='text-primary'>{_watchName}</span>, onboarding finalizado!
                    </h1>
                    <h2>
                        Analizaremos la información recibida y en 24 o 48 horas te avisaremos<br />
                        cuando tu línea de crédito esté aprobada para que puedas empezar a pagar a plazos.
                    </h2>
                    <br />
                    <h3 style={{ "color": "#A9A9A9" }}>
                        ¿Necesitas ayuda?<br />
                        <a href="https://chat.r2capital.co?chat=bnpl">Escríbenos por WhatsApp</a>
                    </h3>
                    <br />
                </div>
            }
            {alreadyCompleted &&
                <div>
                    <h1 className="text-2xl md:text-4xl font-semibold md:mt-40 my-20 max-w-[450px] self-start">
                        Tu onboarding está en revisión!
                    </h1>
                    <h2>
                        Analizaremos la información recibida y en 24 o 48 horas te avisaremos<br />
                        cuando tu línea de crédito esté aprobada para que puedas empezar a pagar a plazos.
                    </h2>
                    <br />
                    <h3 style={{ "color": "#A9A9A9" }}>
                        ¿Necesitas ayuda?<br />
                        <a href="https://chat.r2capital.co?chat=bnpl">Escríbenos por WhatsApp</a>
                    </h3>
                    <br />
                </div>
            }
        </FormProvider>
    </AntdLayout >

}