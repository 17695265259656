import {
    Button, DatePicker,
    FilterDropdown,
    FilterDropdownProps, Input,
    List,
    Table,
    TextField,
    UrlField,
    useTable
} from "@pankod/refine-antd";
import { DownloadOutlined } from "@ant-design/icons";
import { IBuyer } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel } from "../../utils/common";

const { RangePicker } = DatePicker;


export const BuyerList: React.FC = () => {
    const { tableProps, sorter } = useTable<IBuyer>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "buyer_id",
                "extra_data",
                "seller_id",
                "created_at",
                "updated_at",
                "external_id",
                "name",
                "phone_number",
                "tax_id_type",
                "tax_id_code",
                {
                    seller: ['seller_id', 'name']
                }
            ],
        },
    });

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, string>([
                ["buyer_id", "Buyer Id"],
                ["updated_at", "Updated at"],
                ["external_id", "External Id"],
                ["tax_id_type", "Id type"],
                ["tax_id_code", "Id code"],
                ["seller.name", "Seller"],
                ["name", "Name"],
                ["phone_number", "Phone"],
            ]), `BNPL_BuyerList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="buyer_id"
                expandable={{ expandedRowRender: ExpandedRowRenderFields() }}
            >
                <Table.Column dataIndex="buyer_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="external_id"
                    title="External ID"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="tax_id_type"
                    title="ID TYPE"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="tax_id_code"
                    title="ID CODE"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<IBuyer>
                    dataIndex={["seller", "name"]}
                    title="seller"
                    render={(value, record) => {

                        const seller = record.seller
                        return (
                            <Link to={`sellers/${seller.seller_id}`}>
                                <UrlField
                                    value={seller.name}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="name"
                    title="Name"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="phone_number"
                    title="Phone"
                    render={(value) => <TextField value={value} />}
                />
            </Table>
        </List>
    );
};