import {
    Button, Card, DatePicker, FilterDropdown, FilterDropdownProps, Input, List, NumberField,
    Popover, Table, TextField, UrlField, useTable
} from "@pankod/refine-antd";
import { PopupBuyerContent } from "../../components/layout/buyer-popup";

import { DownloadOutlined } from "@ant-design/icons";
import { ICheckout, ICollection } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel } from "../../utils/common";
import Swal from "sweetalert2";
import axios from "axios";
import { usePermissions } from "@pankod/refine-core";
const { RangePicker } = DatePicker;

export const CollectionList: React.FC = () => {
    const { data: permissionsData } = usePermissions();

    const [showButton, setshowButton] = React.useState(false)
    const [cancelCollectionButton, setCancelCollectionButton] = React.useState(false)

    React.useEffect(() => {
        if (permissionsData && permissionsData.defaultRole === "manager") {
            setshowButton(true)
        }
    }, [permissionsData])

    const { tableProps, sorter } = useTable<ICollection>({
        initialCurrent: 1,
        initialPageSize: 500,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "collection_id",
                "checkout_id",
                "external_id",
                "status",
                "status_history",
                "amount",
                "collection_type",
                "payment_type",
                "collection_time",
                "collection_date",
                "observations",
                "extra_data",
                "created_at",
                "updated_at",
                "payment_id",
                "webhook_url",
                "webhook_status",
                "webhook_attempt",
                "related_collection_id",
                "sub_collection_type",
                {
                    credit_line: [
                        'credit_line_id',
                        'currency',
                        {
                            buyer: [
                                'name',
                                'external_id',
                                'extra_data',
                                'buyer_id',
                                {
                                    seller: [
                                        'seller_id', 'name',
                                        {
                                            platform: [
                                                'platform_id', 'country'
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
        },
    });

    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
        height: '8em',
    };


    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, string>([
                ["collection_id", "Collection Id"],
                ["collection_type", "Collection Type"],
                ["created_at", "Created at"],
                ["updated_at", "Updated at"],
                ["credit_line.buyer.seller.name", "Seller"],
                ["credit_line.buyer.name", "Buyer"],
                ["credit_line.buyer.buyer_id", "Buyer ID"],
                ["credit_line.credit_line_id", "Credit line"],
                ["status", "Status"],
                ["amount", "Amount"],
                ["credit_line.currency", "Currency"],
                ["payment_id", "payment_id"],
                ["webhook_url", "webhook_url"],
                ["webhook_status", "webhook_status"],
                ["related_collection_id", "related_collection_id"],
                ["collection_date", "collection_date"],
                ["collection_time", "collection_time"],
                ["sub_collection_type", "Sub Collection Type"],
            ]), `BNPL_CollectionList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000, 5000, 10000]
                }}
                rowKey="collection_id"
                expandable={{
                    expandedRowRender: record => {
                        const extra_data = ExpandedRowRenderFields({ jsonField: 'extra_data' })
                        return <div>
                            <Card.Grid
                                style={gridStyle}>webhook_url:<strong>  {record.webhook_url}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>webhook_status:<strong>  {record.webhook_status}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>webhook_attempt:<strong>  {record.webhook_attempt}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>external_id:<strong>  {record.external_id}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>status_history:<strong>  {record.status_history}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>collection_type:<strong>  {record.collection_type}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>payment_type:<strong>  {record.payment_type}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>collection_date:<strong>  {record.collection_date}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>observations:<strong>  {record.observations}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>extra_data:<strong>  {extra_data}</strong></Card.Grid>
                        </div>;
                    }
                }}
            >
                {showButton ? (
                    <Table.Column<ICollection> dataIndex="collection_id" title="Cancel?"
                        render={(value, record) => (
                            <Button type="primary" danger ghost loading={cancelCollectionButton} onClick={() => {
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: 'You want to cancel the collection ' + value + ' ($' + record.amount + ")",
                                    icon: 'warning',
                                    showDenyButton: true,
                                    confirmButtonText: 'Yes'
                                }).then((option) => {
                                    if (option.isConfirmed) {
                                        console.log(value, record)
                                        debugger
                                        const config = {
                                            headers: {
                                                "partner_id": record.credit_line?.buyer?.seller.platform.platform_id,
                                                "Authorization": record.credit_line?.buyer?.seller.platform.country === "MX" ? localStorage.getItem("mxr2token") : localStorage.getItem("r2token"),
                                            }
                                        }
                                        let url = process.env.REACT_APP_BNPL_API_URL
                                        if (url === undefined) {
                                            url = ""
                                        }

                                        setCancelCollectionButton(true)

                                        axios.delete(`${url}/collection/cancel/${value}`, config).then((result) => {
                                            // add swal
                                            Swal.fire({
                                                title: 'Checkout CANCELED',
                                                text: 'Balance updated',
                                                icon: 'success'
                                            }).then(() => window.location.reload());
                                        }).catch((error) => {
                                            console.log(error);
                                            Swal.fire({
                                                title: 'NOT CANCELED',
                                                text: 'Ups, something went wrong, ' + error.response.data.error,
                                                icon: 'error'
                                            });
                                        }).finally(() => setCancelCollectionButton(false))
                                    }
                                })
                            }}>
                                {"CANCEL"}
                            </Button>
                        )}
                    />
                )
                    :
                    null
                }
                <Table.Column dataIndex="collection_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column dataIndex="checkout_id" title="Checkout ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="collection_type"
                    title="collectionType"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="payment_type"
                    title="paymentType"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICollection>
                    dataIndex={["credit_line", "buyer", "seller", "seller_id"]}
                    title="Seller"
                    render={(value, record) => {

                        const seller = record.credit_line?.buyer?.seller
                        return (
                            <Link to={`sellers/${seller?.seller_id}`}>
                                <UrlField
                                    value={seller?.name}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICollection>
                    dataIndex={["credit_line", "buyer", "external_id"]}
                    title="Buyer"
                    render={(value, record) =>
                        <Popover
                            content={<PopupBuyerContent buyer={record.credit_line?.buyer} />}
                            title="Buyer"
                        >
                            <TextField value={record.credit_line?.buyer?.external_id} />
                        </Popover>
                    }
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICollection>
                    dataIndex={["credit_line_id"]}
                    title="Credit Line"
                    render={(value, record) => {

                        const line = record.credit_line

                        // console.log(collection)
                        return (
                            <Link to={`lines/${line?.credit_line_id}`}>
                                <UrlField
                                    value={line?.credit_line_id}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="status"
                    title="Status"
                    render={(value) => <TextField
                        value={value}
                    />}
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="amount"
                    title="Amount"
                    render={(value) => <NumberField
                        value={value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column<ICollection>
                    dataIndex="currency"
                    title="Currency"
                    render={(value, record) => {

                        const currency = record.credit_line?.currency;
                        return (<TextField value={currency} />);
                    }}
                />
                <Table.Column
                    dataIndex="payment_id"
                    title="payment ID"
                    render={(value) => <TextField
                        value={value}
                    />}
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="sub_collection_type"
                    title="sub collection type"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
            </Table>
        </List>
    );
};