import { DatePicker, Button, FilterDropdown, FilterDropdownProps, Input, List, Table, TextField, useTable, UrlField } from "@pankod/refine-antd";

import { DownloadOutlined } from "@ant-design/icons";
import { IPayin_Provider } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel } from "../../utils/common";
import { Link } from "react-router-dom";

const { RangePicker } = DatePicker;

export const PayinProviderList: React.FC = () => {
    const { tableProps } = useTable<IPayin_Provider>({
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "payin_id",
                "platform_id",
                "command",
                "provider",
                "credit_line_id",
                "description",
                "extra_data",
                "pay_in_request",
                "pay_in_response",
                "created_at",
                "updated_at",
                "deleted_at",
                "provider_data",
                "provider_id",
                "due_date",
                "checkout_id",
                {
                    credit_line: [
                        {
                            buyer: ["name", "email", 'external_id']
                        }
                    ]
                },
                {
                    platform: [
                        "name",
                        "country"
                    ]
                }
            ],
        }
    });

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                ["payin_id", "Payin ID"],
                ["credit_line.buyer.name", "Buyer Name"],
                ["credit_line.buyer.email", "Email"],
                ["credit_line.buyer.external_id", "External ID"],
                ["platform_id", "Platform ID"],
                ["command", "Command"],
                ["provider", "Provider"],
                ["credit_line_id", "Credit line ID"],
                ["description", "Description"],
                ["created_at", "created at"],
                ["updated_at", "updated at"],
                ["deleted_at", "deleted at"],
                ["provider_data", "Payment Link"],
                ["provider_id", "provider id"],
                ["due_date", "due date"],
                ["checkout_id", "checkout ID"],
            ]), `BNPL_PayinProviderList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="payin_id"
                expandable={{ expandedRowRender: ExpandedRowRenderFields() }}
            >
                <Table.Column<IPayin_Provider>
                    dataIndex={["platform", "name"]}
                    title="Partner name"
                    render={(value, record) => {

                        const platform = record.platform
                        return (
                            <p>
                                {platform?.name}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<IPayin_Provider>
                    dataIndex={["platform", "name"]}
                    title="Country"
                    render={(value, record) => {

                        const platform = record.platform
                        return (
                            <p>
                                {platform?.country}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column dataIndex="payin_id" title="PAY IN ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column<IPayin_Provider>
                    dataIndex={["credit_line", "buyer", "name"]}
                    title="Buyer name"
                    render={(value, record) => {

                        const buyer = record.credit_line?.buyer
                        return (
                            <p>
                                {buyer?.name}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<IPayin_Provider>
                    dataIndex={["credit_line", "buyer", "email"]}
                    title="Buyer name"
                    render={(value, record) => {

                        const buyer = record.credit_line?.buyer
                        return (
                            <p>
                                {buyer?.email}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<IPayin_Provider>
                    dataIndex={["credit_line", "buyer", "external_id"]}
                    title="External ID"
                    render={(value, record) => {

                        const buyer = record.credit_line?.buyer
                        return (
                            <p>
                                {buyer?.external_id}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="credit_line_id"
                    title="Credit Line ID"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="checkout_id"
                    title="Checkout ID"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="provider_data"
                    title="Payment Link"
                    render={(value) => {

                        return (
                            <Link to={`${value}`} target="_blank">
                                <UrlField
                                    value={value}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
            </Table>
        </List>
    );
};