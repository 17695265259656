import {
    BooleanField,
    Button, DatePicker,
    EditButton,
    FilterDropdown,
    FilterDropdownProps,
    Form, Input,
    List,
    NumberField,
    SaveButton,
    Space,
    Table,
    TagField,
    TextField,
    UrlField,
    useEditableTable
} from "@pankod/refine-antd";
import { useExport } from "@pankod/refine-core";

import { DownloadOutlined } from "@ant-design/icons";
import { IOffer, IOnboarding } from "interfaces";
import * as React from "react";
import { Link } from "react-router-dom";
import { exportToExcel } from "../../utils/common";
import { split } from "lodash";

const { RangePicker } = DatePicker;

const URL_PREFIX_ONBOARDING = 'https://bnpl-admin.r2capital.co/form?id='
//const URL_PREFIX_ONBOARDING = 'http://localhost:1234/form?id='

export const OfferList: React.FC = () => {

    const {
        tableProps,
        formProps,
        isEditing,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
        sorter,
        filters,
    } = useEditableTable<IOffer>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "offer_id",
                "extra_data",
                "seller_id",
                "created_at",
                "updated_at",
                "max_amount",
                "currency",
                "buyer_external_id",
                "token",
                "status",
                "is_generic",
                {
                    seller: [
                        'seller_id',
                        'name',
                    ]
                },
                {
                    onboardings: [
                        'extra_data',
                        'name'
                    ]
                }
            ],
        },
    });

    const { triggerExport, isLoading: isLoadingExport } = useExport<IOffer>({
        metaData: {
            fields: [
                "offer_id",
                "extra_data",
                "seller",
                "seller_id",
                "created_at",
                "updated_at",
                "max_amount",
                "currency",
                "buyer_external_id",
                "token",
                "status",
                "is_generic",
            ],
        },
        mapData: (item) => {
            return {
                ...item,
                link: `${URL_PREFIX_ONBOARDING}${item.token}`
            }
        }
    });

    const handleSearchByDate = (selectedKeys: any, confirm: any, dataIndex: any) => {
        console.log(selectedKeys, confirm, dataIndex);
        filters?.splice(0, filters?.length)
        filters?.push({
            field: dataIndex,
            value: selectedKeys[0],
            operator: "eq"
        })
        confirm();
        // setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex
        // });
    }

    const handleReset = (clearFilters: any) => {
        clearFilters();
        // console.log(this.state.searchText);
        // this.setState({ searchText: "" });
    }

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                ["offer_id", "Id"],
                ["created_at", "Created at"],
                ["updated_at", "Updated at"],
                ["seller.name", "Seller"],
                ["buyer_external_id", "Buyer"],
                ["name", "Name"],
                ["max_amount", "Max amount"],
                ["currency", "Currency"],
                ["status", "Status"],
                ["onboarding_url", { name: "Onboarding url", func: (row: any) => `${URL_PREFIX_ONBOARDING}${row.token}` }],
                ["is_generic", "Generic"],
            ]), `BNPL_OfferList`)} />
            <Form {...formProps}>
                <Table {
                    ...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ["bottomRight"],
                        size: "default",
                        pageSizeOptions: [50, 100, 200, 500, 1000]
                    }}
                    rowKey="offer_id"
                >
                    <Table.Column dataIndex="offer_id" title="ID"
                        sorter
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <Input />
                            </FilterDropdown>
                        )} />
                    <Table.Column
                        dataIndex="created_at"
                        title="createdAt"
                        render={(value) => {
                            const today = Date.parse(split(value, "+")[0])
                            const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                            return (<p>{newValue}</p>)
                        }}
                        sorter
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <RangePicker format={"YYYY-MM-DD"} />
                            </FilterDropdown>
                        )}
                    />
                    <Table.Column
                        dataIndex="updated_at"
                        title="updatedAt"
                        render={(value) => {
                            const today = Date.parse(split(value, "+")[0])
                            const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                            return (<p>{newValue}</p>)
                        }}
                        sorter
                    />
                    <Table.Column<IOffer>
                        dataIndex={["seller", "name"]}
                        title="Seller"
                        sorter
                        render={(value, record) => {
                            const seller = record.seller
                            return (
                                <Link to={`sellers/${seller?.seller_id}`}>
                                    <UrlField
                                        value={seller?.name}
                                    />
                                </Link>
                            );
                        }}
                        // onFilter={(value, record) => {
                        //     return record.seller.name === value
                        // }}
                        // filterDropdown={({setSelectedKeys, confirm, ...props}: FilterDropdownProps) => (
                        //     <>
                        //         <Input onChange={(e) => setSelectedKeys([e.target.value])}/>
                        //         <Button onClick={() => {
                        //             confirm({closeDropdown: true})
                        //         }}>Filter</Button>
                        //     </>
                        // )}
                        // filterDropdown={({
                        //                      selectedKeys,
                        //                      setSelectedKeys,
                        //                      confirm,
                        //                      clearFilters
                        //                  }: FilterDropdownProps) => (
                        //     <>
                        //         <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                        //             <Col className="gutter-row">
                        //                 <Input
                        //                     onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        //                     onPressEnter={() => handleSearch(selectedKeys, confirm, ["seller", "name"])}
                        //                     value={selectedKeys[0]}
                        //                 />
                        //             </Col>
                        //         </Row>
                        //         <Row>
                        //             <Col className="gutter-row">
                        //                 <Button
                        //                     onClick={() => handleSearch(selectedKeys, confirm, ["seller", "name"])}>Filter</Button>
                        //             </Col>
                        //             <Col className="gutter-row">
                        //                 <Button onClick={() => handleReset(clearFilters)} size="small"
                        //                         style={{width: 90}}>
                        //                     Reset
                        //                 </Button>
                        //             </Col>
                        //         </Row>
                        //     </>
                        // )}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <Input />
                            </FilterDropdown>
                        )}
                    />
                    <Table.Column<IOffer>
                        dataIndex={'buyer_external_id'}
                        title="Buyer"
                        sorter
                        render={(value) => <TextField value={value} />}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <Input />
                            </FilterDropdown>
                        )} />
                    <Table.Column<IOffer>
                        dataIndex={['buyer_external_id']}
                        title="Name"
                        render={(value, record) => {
                            if (record.onboardings && record.onboardings[0] !== undefined) {
                                const onboarding: IOnboarding = record.onboardings[0]
                                return <TextField value={onboarding?.name} />
                            }
                        }}
                    />
                    <Table.Column<IOffer>
                        dataIndex="max_amount"
                        title="Max Amount"
                        sorter
                        render={(value, record) => {
                            if (isEditing(record.offer_id)) {
                                return (
                                    <Form.Item
                                        name="max_amount"
                                        style={{ margin: 0 }}
                                    >
                                        <Input type={"number"} />
                                    </Form.Item>
                                );
                            }
                            return <NumberField
                                value={value}
                                options={{
                                    style: 'currency',
                                    currency: 'USD',
                                    currencyDisplay: 'narrowSymbol',
                                    maximumFractionDigits: 2,
                                }}
                            />
                        }}
                    />
                    <Table.Column
                        dataIndex="currency"
                        title="Currency"
                        render={(value) => <TextField value={value} />}
                    />
                    <Table.Column
                        dataIndex="status"
                        title="Status"
                        render={(value) => <TagField value={value} />}
                        filterDropdown={(props: FilterDropdownProps) => (
                            <FilterDropdown {...props}>
                                <Input />
                            </FilterDropdown>
                        )} />
                    <Table.Column
                        dataIndex="token"
                        title="Onboarding URL"
                        render={(value) => <UrlField value={'link'} href={`${URL_PREFIX_ONBOARDING}${value}`}
                            target="_blank" />}
                    />
                    <Table.Column
                        dataIndex="is_generic"
                        title="Generic"
                        render={(value) => <BooleanField value={value} />}
                    />
                    <Table.Column<IOffer>
                        title="Actions"
                        dataIndex="actions"
                        key="actions"
                        render={(_text, record) => {
                            if (isEditing(record.offer_id)) {
                                return (
                                    <Space>
                                        <SaveButton
                                            {...saveButtonProps}
                                            size="small"
                                        />
                                        <Button
                                            {...cancelButtonProps}
                                            size="small"
                                        >
                                            Cancel
                                        </Button>
                                    </Space>
                                );
                            }
                            return (
                                <Space>
                                    <EditButton
                                        {...editButtonProps(record.offer_id)}
                                        size="small"
                                    />
                                </Space>
                            );
                        }}
                    />
                </Table>
            </Form>
        </List>
    );
};