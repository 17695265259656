import { AuthProvider } from "@pankod/refine-core";
import {
  CognitoUserPool,
  // CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import { client } from "./dataProvider";
import jwt from "jsonwebtoken"

function jwtDecode(t: string) {
  let token = {} as any;
  token.raw = t;

  token.header = JSON.parse(window.atob(t.split('.')[0]));
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return (token)
}

const userPool = new CognitoUserPool({
  UserPoolId: 'us-west-2_t5SDpNAIm',
  ClientId: '6661qlm3lcmdmckvkl4keritjj',
})

const getJwtToken = (accessToken: string) => {
  const { payload } = jwtDecode(accessToken)
  // console.log(header, payload)
  // if (payload['custom:PlatformId'] !== undefined) {
  //   const partner_id = payload['custom:PlatformId']
  //   localStorage.setItem("partner_id", partner_id);
  // }

  const mxr2payload = {
    "country": "MX"
  }
  const r2payload = {
  }
  if (payload['custom:JWTSecret'] !== undefined) {
    const r2Token = jwt.sign(r2payload, payload['custom:JWTSecret'], {
      expiresIn: "2h",
      header: {
        "typ": "JWT",
        "alg": "HS256",
        "kid": payload['custom:kid'],
      }
    });
    const mxr2Token = jwt.sign(mxr2payload, payload['custom:JWTSecret'], {
      expiresIn: "2h",
      header: {
        "typ": "JWT",
        "alg": "HS256",
        "kid": payload['custom:kid'],
      }
    });
    localStorage.setItem("r2token", r2Token);
    localStorage.setItem("mxr2token", mxr2Token);
  } else {
    const s: any = process.env.DEFAULT_S
    const k = process.env.DEFAULT_K
    const s_kalto: any = process.env.DEFAULT_S_KALTO
    const k_kalto = process.env.DEFAULT_K_KALTO
    const r2Token = jwt.sign(r2payload, s, {
      expiresIn: "2h",
      header: {
        "typ": "JWT",
        "alg": "HS256",
        "kid": k,
      }
    });
    const mxr2Token = jwt.sign(mxr2payload, s, {
      expiresIn: "2h",
      header: {
        "typ": "JWT",
        "alg": "HS256",
        "kid": k,
      }
    });
    const mxKaltor2Token = jwt.sign(r2payload, s_kalto, {
      expiresIn: "2h",
      header: {
        "typ": "JWT",
        "alg": "HS256",
        "kid": k_kalto,
      }
    });
    localStorage.setItem("r2token", r2Token);
    localStorage.setItem("mxr2token", mxr2Token);
    localStorage.setItem("mxkaltor2token", mxKaltor2Token);
  }
}


export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    // if (username === "admin" && password === "admin") {
    //   localStorage.setItem(TOKEN_KEY, username);
    //   return Promise.resolve();
    // }
    // return Promise.reject(new Error("username: admin, password: admin"));

    // return Promise.resolve();

    username = username.trim()

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getIdToken().getJwtToken();
          // console.log("login ", result.getIdToken());
          localStorage.setItem('username', username)
          localStorage.setItem('token', accessToken);

          getJwtToken(accessToken)

          if (accessToken && accessToken !== '')
            client.setHeader('authorization', `Bearer ${accessToken}`)
          resolve()
        },

        onFailure: function (err) {
          console.log(err.message || JSON.stringify(err));
          reject()
        },
      });
    })

  },
  logout: () => {
    try {
      var cognitoUser = new CognitoUser({
        Username: localStorage.getItem('username')!,
        Pool: userPool,
      })
      cognitoUser.signOut()

    } catch (err) {
      return Promise.reject()
    }
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    localStorage.removeItem('r2token');
    localStorage.removeItem('partner_id');

    client.setHeader('authorization', '')

    return Promise.resolve();
  },
  checkError: (error) => {
    console.log(JSON.stringify(error))
    // console.log(body)
    // if (error.status === 200) {
    if (error.message.includes("JWTExpired") ||
      error.message.includes("JWSError") ||
      error.message.includes("JWSInvalidSignature")) {
      localStorage.removeItem('token');
      localStorage.removeItem('r2token');
      localStorage.removeItem('partner_id');

      return Promise.reject();
    }
    // } else if (error.status > 400) {
    //     return Promise.reject()
    // }

    return Promise.resolve();

  },
  checkAuth: () => {

    const token = localStorage.getItem('token')
    if (token) {
      getJwtToken(token)
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: (params) => {
    const token = localStorage.getItem('token')
    if (token && token !== '') {
      try {
        getJwtToken(token)
        const { payload } = jwtDecode(token)
        // console.log(header, payload)
        const claims = JSON.parse(payload['https://hasura.io/jwt/claims'])
        //console.log("Claims ", claims )
        const allowedRoles = claims['x-hasura-allowed-roles']
        const defaultRole = claims['x-hasura-default-role']

        return Promise.resolve({
          allowedRoles,
          defaultRole,
        })
      } catch (err) {
        console.log(err)
        return Promise.reject({ allowedRoles: [], defaultRole: '' })
      }
    }
    return Promise.reject({ allowedRoles: [], defaultRole: '' }) //{ allowedRoles: [], defaultRole: '' } //Promise.reject('Unknown method')

  },
  getUserIdentity: async () => {
    const token = localStorage.getItem('token')
    if (token && token !== '') {
      //const { payload } = jwtDecode(token)
      // console.log(header, payload)
      //const claims = payload['https://hasura.io/jwt/claims']
      // const userId = claims['x-hasura-user-id']
      return Promise.resolve({
        id: 'user',
        // fullName: i18nProvider.translate('resources.common.fields.home'),
        // avatar: 'https://avatars.githubusercontent.com/u/2219914?v=4',
      })
    }
    return Promise.reject()

  },
};
