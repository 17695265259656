import { DownloadOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    FilterDropdown,
    FilterDropdownProps,
    Input,
    List, Row,
    Select,
    Space,
    Table,
    TagField,
    TextField,
    UrlField,
    useEditableTable
} from "@pankod/refine-antd";
import { useDataProvider } from "@pankod/refine-core";
import axios from "axios";
import { IOnboarding } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal2 from "sweetalert2";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel, URL_PREFIX_DOCS_S3 } from "../../utils/common";

// Added 
export const createBlob = (b64Data: string, extension = '', sliceSize = 512) => {
    let contentType = "";
    switch (extension) {
        case "png":
        case "jpg":
        case "jpeg":
            contentType = `image/${extension}`;
            break;
        case "pdf":
        case "zip":
            contentType = `application/${extension}`;
            break;
    }
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
}

const { RangePicker } = DatePicker;

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export const OnboardingList: React.FC = () => {
    const dataProvider = useDataProvider()()
    const [downloadDocumentsLoading, setDownloadDocumentsLoading] = useState(false);
    useEffect(() => {
        if (downloadDocumentsLoading) {
            document.body.style.cursor = "wait";
        } else {
            document.body.style.cursor = "default";
        }
    }, [downloadDocumentsLoading]);

    const { tableProps, sorter } = useEditableTable<IOnboarding>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "onboarding_id",
                "extra_data",
                "status",
                "offer_id",
                "created_at",
                "onboarding_date",
                "external_id",
                "name",
                "tax_id_code",
                "person_type",
                "commercial_name",
                "legalrep_name",
                "email",
                "phone_number",
                "activity",
                "identity_doc_type",
                "identity_doc_front_img",
                "identity_doc_back_img",
                "constitution_certif_img",
                "address_proof_img",
                "employees_qty_range",
                "social_media",
                "commercial_oper_range",
                "check_confirm_info",
                "check_terms_conditions",
                "check_privacy_policy",
                {
                    offer: [
                        { seller: ['seller_id', 'name'] }
                    ]
                }

            ],
        },
    });

    const stateActions = [
        { label: 'Review', value: 'REVIEWING' },
        { label: 'Approve KYC', value: 'APPROVE-KYC' },
        { label: 'Reject KYC', value: 'REJECT-KYC' },
        { label: 'Return KYC', value: 'RETURN-KYC' },
    ]

    // console.log(tableProps.dataSource)
    const onChangeAction = (record: IOnboarding) => async (value: string) => {
        //console.log('action: ', value)

        if (value === "APPROVE-KYC") {
            await Swal2.fire({
                title: '¿Estás seguro que quieres activar?',
                text: 'Esto creará una línea de crédito y enviará una notificación automática al cliente.',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'No, no estoy seguro.',
                showConfirmButton: true,
                confirmButtonText: 'Si !',
                showDenyButton: false,
            }).then((response) => {
                if (response.isConfirmed) {
                    callToFunction(record, value)
                }
            });
        } else {
            callToFunction(record, value)
        }
    }

    const callToFunction = (record: IOnboarding, value: string) => {
        let url = process.env.REACT_APP_API_URL
        if (url === undefined) {
            url = ""
        }
        url = url + process.env.REACT_APP_GRAPHQL_SUFFIX
        dataProvider?.custom({
            method: 'post',
            url: url,
            metaData: {
                operation: 'bnpl_change_onboarding_status' + process.env.BNPL_STAGE,
                fields: ['state'],
                variables: {
                    onboarding_id: { value: record.onboarding_id, required: true },
                    action: { value: value, required: true },
                }
            }
        }).then(json => json.data).then((result) => {
            //console.log(result)
            Swal2.fire({
                title: 'Status changed',
                icon: 'success',
            });
        }).catch((err) => {
            Swal2.fire({
                title: 'Oops, we can not change the status',
                text: err,
                icon: 'error',
            });
        });
    }

    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
        height: '8em',
    };

    function resolveBlob(response: { headers?: any; data: any; }) {
        const headerval = response.headers['content-disposition'];
        if (headerval != null) {
            let filename = headerval.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
            filename = decodeURI(filename);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
        } else {
            handleKnownException(response);
        }
    }

    function handleKnownException(response: { data: Blob; }) {
        const reader = new FileReader();
        reader.onload = function () {
            if (reader.result !== null) {
                const responseData = JSON.parse(reader?.result);
                if (responseData.code === 500) {
                    alert(responseData.msg);
                }
            }
        }
        reader.readAsText(response.data);
    }

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"}
                onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                    ["onboarding_id", "Id"],
                    ["created_at", "Created at"],
                    ["updated_at", "Updated at"],
                    ["offer.seller.name", "Seller"],
                    ["offer_id", "Offer"],
                    ["external_id", "External Id"],
                    ["name", "Name"],
                    ["status", "Status"],
                    ["docs_url", {
                        name: "Docs url",
                        func: (row: any) => `${URL_PREFIX_DOCS_S3}/${row?.tax_id_code?.toUpperCase()}/`
                    }],
                ]), `BNPL_OnboardingList`)} />
            <Table
                {...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="onboarding_id"
                expandable={{
                    expandedRowRender: record => {
                        const socialMedia = JSON.stringify(record.social_media)
                        const extra_data = ExpandedRowRenderFields({ jsonField: 'extra_data' })
                        return <div>
                            <Card.Grid style={gridStyle}>tax_id_code:<strong>  {record.tax_id_code}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>person_type:<strong>  {record.person_type}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>commercial_name:<strong>  {record.commercial_name}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>legalrep_name:<strong>  {record.legalrep_name}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>email:<strong>  {record.email}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>activity:<strong>  {record.activity}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>identity_doc_type:<strong>  {record.identity_doc_type}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>identity_doc_front_img:<strong>  {record.identity_doc_front_img}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>identity_doc_back_img:<strong>  {record.identity_doc_back_img}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>constitution_certif_img:<strong>  {record.constitution_certif_img}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>address_proof_img:<strong>  {record.address_proof_img}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>employees_qty_range:<strong>  {record.employees_qty_range}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>social_media:<strong> {socialMedia}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>commercial_oper_range:<strong>  {record.commercial_oper_range}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>check_confirm_info:<strong>  {record.check_confirm_info}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>check_terms_conditions:<strong>  {record.check_terms_conditions}</strong></Card.Grid>
                            <Card.Grid
                                style={gridStyle}>check_privacy_policy:<strong>  {record.check_privacy_policy}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>extra_data:<strong>  {extra_data}</strong></Card.Grid>
                        </div>;

                    }
                }}
            >
                <Table.Column dataIndex="onboarding_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<IOnboarding>
                    dataIndex={["offer", "seller", "name"]}
                    title="seller"
                    render={(value, record) => {

                        const seller = record.offer.seller
                        return (
                            <Link to={`sellers/${seller.seller_id}`}>
                                <UrlField
                                    value={seller.name}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="offer_id"
                    title="offer"
                    render={(value) => {

                        const onboarding = tableProps.dataSource?.find(
                            (item) => item.offer_id === value,
                        )

                        // console.log(onboarding)
                        return (
                            <Link to={`offers/${onboarding?.offer_id}`}>
                                <UrlField
                                    value={onboarding?.offer_id}
                                />
                            </Link>
                        );
                    }}
                />
                <Table.Column
                    dataIndex="external_id"
                    title="External ID"
                    render={(value) => <TextField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="name"
                    title="Name"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column<IOnboarding>
                    key="status"
                    dataIndex={"status"}
                    title="Status"
                    render={(value, record) => {
                        return (<div className="flex">
                            <div className="w-32">
                                <TagField value={value} className="mr-5" />
                            </div>
                            <Select
                                className="w-40"
                                placeholder="Action"
                                onChange={onChangeAction(record)}
                            >
                                {stateActions.map((action, i) =>
                                    <Select.Option
                                        key={i}
                                        value={action.value}>
                                        {action.label}
                                    </Select.Option>
                                )}
                            </Select>
                        </div>);
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="tax_id_code"
                    title="Docs URL"
                    render={(value) => <Row justify="space-around" align="middle">
                        <Space>
                            <div className="w-32">
                                <Col span={12}>
                                    <UrlField
                                        value={'link to S3'}
                                        href={encodeURI(`${URL_PREFIX_DOCS_S3}/${value?.toUpperCase()}/`)}
                                        target="_blank"
                                    />
                                </Col>
                            </div>
                            <Col span={12}>
                                <Button icon={<DownloadOutlined />} loading={downloadDocumentsLoading}
                                    disabled={downloadDocumentsLoading} onClick={async () => {
                                        setDownloadDocumentsLoading(true);
                                        axios.request({
                                            method: "POST",
                                            url: process.env.S3_GET_FILES,
                                            data: {
                                                key: `kyc/${value?.toUpperCase()}`,
                                                bucket: "kalto.r2capital.co"
                                            }
                                        }).then(response => {
                                            console.log(response.data)
                                            window.open(response.data?.url, "_blank")
                                        }).catch(error => {
                                            console.error(error)
                                        }).finally(() => setDownloadDocumentsLoading(false))
                                    }
                                    } >Download docs</Button>
                            </Col>
                        </Space>
                    </Row>
                    }
                />
            </Table>
        </List>
    );
};