import {
    BooleanField, Button, List, Table, TextField, useTable
} from "@pankod/refine-antd";

import { DownloadOutlined } from "@ant-design/icons";
import { IPlatform } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { ExpandedRowRenderFields } from "../../components/layout/utils/expandableTable";
import { exportToExcel } from "../../utils/common";

export const PlatformList: React.FC = () => {
    const { tableProps } = useTable<IPlatform>({
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "platform_id",
                "extra_data",
                "name",
                "created_at",
                "updated_at",
                "simple_platform"
            ],
        }
    });


    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, any>([
                ["platform_id", "Id"],
                ["created_at", "Created at"],
                ["updated_at", "Updated at"],
                ["name", "Name"],
                ["simple_platform", "Simple"],
            ]), `BNPL_PlatformList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000]
                }}
                rowKey="platform_id"
                expandable={{ expandedRowRender: ExpandedRowRenderFields() }}
            >
                <Table.Column dataIndex="platform_id" title="ID" />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                />
                <Table.Column
                    dataIndex="name"
                    title="Name"
                    render={(value) => <TextField value={value} />}
                />
                <Table.Column
                    dataIndex="simple_platform"
                    title="Simple"
                    render={(value) => <BooleanField value={value} />}
                />
                {/* <Table.Column
                    dataIndex="data"
                    title="data"
                    render={(value) => <TextField value={JSON.stringify(value)} />}
                /> */}
            </Table>
        </List>
    );
};