import {DownloadOutlined} from "@ant-design/icons";
import {
    Button,
    DateField,
    DatePicker,
    FilterDropdown,
    FilterDropdownProps,
    Input,
    List,
    NumberField,
    Table,
    TagField,
    TextField,
    useTable
} from "@pankod/refine-antd";

import {ICheckout} from "interfaces";
import * as React from "react";
import {exportToExcel} from "../../utils/common";


const {RangePicker, WeekPicker} = DatePicker;

export const CheckoutCollectionViewList: React.FC = () => {

    const {tableProps: checkoutTableProps, sorter: checkoutSorter} = useTable<ICheckout>({
        resource: "bnpl" + process.env.BNPL_STAGE + "_checkout",
        initialCurrent: 1,
        initialPageSize: 5000,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "checkout_id",
                "external_id",
                "extra_data",
                "credit_line_id",
                "checkout_time",
                "status",
                "amount",
                "checkout_buyer_cost",
                "checkout_owed_amount",
                "checkout_date",
                "limit_date",
                "updated_at",
                "payment_data",
                {
                    credit_line: [
                        "currency",
                        {
                            buyer: [
                                "name",
                                "extra_data",
                                "external_id",
                                "email",
                                "phone_number",
                                {
                                    seller: ["seller_id", "name", "platform_id"]
                                }
                            ],
                        }
                    ]
                },
                {
                    platform: [
                        "name",
                        "country"
                    ]
                }
            ]
        }
    });

    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
        height: '8em',
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined/>} size={"middle"}
                    onClick={() => exportToExcel(checkoutTableProps.dataSource as any[], new Map<string, string>([
                        ["checkout_id", "Checkout Id"],
                        ["external_id", "external Id"],
                        ["checkout_date", "Checkout date"],
                        ["limit_date", "Due Date"],
                        ["platform.name", "Platform name"],
                        ["platform.country", "Platform country"],
                        ["amount", "Amount"],
                        ["checkout_buyer_cost", "checkout_buyer_cost"],
                        ["checkout_owed_amount", "checkout_owed_amount"],
                        ["status", "Status"],
                        ["payment_data", "Payment Data"],
                        ["credit_line.buyer.phone_number", "Buyer Phone Number"],
                        ["credit_line.buyer.email", "Buyer Email"],
                        ["credit_line.buyer.name", "Buyer Name"],
                        ["updated_at", "Updated At"],
                        ["extra_data.last_collection.collection_date", "Last Collection Date"],
                    ]), `BNPL_CheckoutCollectionView`)}/>
            <Table {
                       ...checkoutTableProps}
                   pagination={{
                       ...checkoutTableProps.pagination,
                       position: ["bottomRight"],
                       size: "default",
                       pageSizeOptions: [50, 100, 200, 500, 1000, 5000, 10000]
                   }}
                   rowKey="checkout_id"
            >
                <Table.Column dataIndex="checkout_id" title="ID"
                              sorter
                              filterDropdown={(props: FilterDropdownProps) => (
                                  <FilterDropdown {...props}>
                                      <Input/>
                                  </FilterDropdown>
                              )}/>
                <Table.Column dataIndex="external_id" title="External ID"
                              sorter
                              filterDropdown={(props: FilterDropdownProps) => (
                                  <FilterDropdown {...props}>
                                      <Input/>
                                  </FilterDropdown>
                              )}/>
                <Table.Column
                    dataIndex="checkout_date"
                    title="checkout_date"
                    render={(value) => {
                        // const today = Date.parse(split(value, "+")[0])
                        // const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today)
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<p>{value}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"}/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="limit_date"
                    title="due_date"
                    render={(value) => {
                        // const today = Date.parse(split(value, "+")[0])
                        // const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today)
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<p>{value}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"}/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["platform", "name"]}
                    title="Partner name"
                    render={(value) => {
                        if (!value) {
                            return (<p></p>);
                        }
                        return (
                            <p>
                                {value}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["platform", "country"]}
                    title="Partner country"
                    render={(value) => {
                        if (!value) {
                            return (<p></p>);
                        }
                        return (
                            <p>
                                {value}
                            </p>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="amount"
                    title="Amount"
                    render={(value) => <NumberField
                        value={value ? value : 0}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column
                    dataIndex="checkout_buyer_cost"
                    title="Buyer cost"
                    render={(value) => <NumberField
                        value={value ? value : 0}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column
                    dataIndex="checkout_owed_amount"
                    title="Owed amount"
                    render={(value) => {
                        let newValue = 0.00
                        if (value) {
                            newValue = value
                        }
                        return (<NumberField
                            value={newValue}
                            options={{
                                style: 'currency',
                                currency: 'USD',
                                currencyDisplay: 'narrowSymbol',
                                maximumFractionDigits: 2,
                            }}
                        />)
                    }}
                />
                <Table.Column
                    dataIndex="status"
                    title="Status"
                    render={(value) => <TagField value={value}/>}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="payment_data"
                    title="Payment Data"
                    render={(value) => <TagField value={value}/>}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["credit_line", "buyer", "phone_number"]}
                    title="Phone Number"
                    render={(value) => {
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<TextField value={"+" + value}/>)
                    }
                    }
                />
                <Table.Column
                    dataIndex={["credit_line", "buyer", "email"]}
                    title="Buyer E-mail"
                    render={(value) => {
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<TextField value={value}/>)
                    }
                    }
                />
                <Table.Column
                    dataIndex={["credit_line", "buyer", "name"]}
                    title="Buyer Name"
                    render={(value) => {
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<TextField value={value}/>)
                    }
                    }
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updated_at"
                    render={(value) => {
                        // const today = Date.parse(split(value, "+")[0])
                        // const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today)
                        if (!value) {
                            return (<p></p>);
                        }
                        return (<p>{value}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"}/>
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["extra_data", "last_collection", "collection_date"]}
                    title="Last Collection Date"
                    render={(value) => {
                        if (!value) {
                            return <p></p>
                        }
                        return (<DateField value={Date.parse(value)} format={"YYYY-MM-DD"}/>)
                    }}
                />
            </Table>
        </List>
    );
};