{
  "name": "@aws-sdk/client-s3",
  "description": "AWS SDK for JavaScript S3 Client for Node.js, Browser and React Native",
  "version": "3.94.0",
  "scripts": {
    "build": "concurrently 'yarn:build:cjs' 'yarn:build:es' 'yarn:build:types'",
    "build:cjs": "tsc -p tsconfig.cjs.json",
    "build:docs": "typedoc",
    "build:es": "tsc -p tsconfig.es.json",
    "build:types": "tsc -p tsconfig.types.json",
    "build:types:downlevel": "downlevel-dts dist-types dist-types/ts3.4",
    "clean": "rimraf ./dist-* && rimraf *.tsbuildinfo",
    "test": "yarn test:unit",
    "test:e2e": "ts-mocha test/**/*.ispec.ts && karma start karma.conf.js",
    "test:unit": "ts-mocha test/**/*.spec.ts"
  },
  "main": "./dist-cjs/index.js",
  "types": "./dist-types/index.d.ts",
  "module": "./dist-es/index.js",
  "sideEffects": false,
  "dependencies": {
    "@aws-crypto/sha1-browser": "2.0.0",
    "@aws-crypto/sha256-browser": "2.0.0",
    "@aws-crypto/sha256-js": "2.0.0",
    "@aws-sdk/client-sts": "3.94.0",
    "@aws-sdk/config-resolver": "3.80.0",
    "@aws-sdk/credential-provider-node": "3.94.0",
    "@aws-sdk/eventstream-serde-browser": "3.78.0",
    "@aws-sdk/eventstream-serde-config-resolver": "3.78.0",
    "@aws-sdk/eventstream-serde-node": "3.78.0",
    "@aws-sdk/fetch-http-handler": "3.78.0",
    "@aws-sdk/hash-blob-browser": "3.78.0",
    "@aws-sdk/hash-node": "3.78.0",
    "@aws-sdk/hash-stream-node": "3.78.0",
    "@aws-sdk/invalid-dependency": "3.78.0",
    "@aws-sdk/md5-js": "3.78.0",
    "@aws-sdk/middleware-bucket-endpoint": "3.80.0",
    "@aws-sdk/middleware-content-length": "3.78.0",
    "@aws-sdk/middleware-expect-continue": "3.78.0",
    "@aws-sdk/middleware-flexible-checksums": "3.78.0",
    "@aws-sdk/middleware-host-header": "3.78.0",
    "@aws-sdk/middleware-location-constraint": "3.78.0",
    "@aws-sdk/middleware-logger": "3.78.0",
    "@aws-sdk/middleware-retry": "3.80.0",
    "@aws-sdk/middleware-sdk-s3": "3.86.0",
    "@aws-sdk/middleware-serde": "3.78.0",
    "@aws-sdk/middleware-signing": "3.78.0",
    "@aws-sdk/middleware-ssec": "3.78.0",
    "@aws-sdk/middleware-stack": "3.78.0",
    "@aws-sdk/middleware-user-agent": "3.78.0",
    "@aws-sdk/node-config-provider": "3.80.0",
    "@aws-sdk/node-http-handler": "3.94.0",
    "@aws-sdk/protocol-http": "3.78.0",
    "@aws-sdk/signature-v4-multi-region": "3.88.0",
    "@aws-sdk/smithy-client": "3.85.0",
    "@aws-sdk/types": "3.78.0",
    "@aws-sdk/url-parser": "3.78.0",
    "@aws-sdk/util-base64-browser": "3.58.0",
    "@aws-sdk/util-base64-node": "3.55.0",
    "@aws-sdk/util-body-length-browser": "3.55.0",
    "@aws-sdk/util-body-length-node": "3.55.0",
    "@aws-sdk/util-defaults-mode-browser": "3.85.0",
    "@aws-sdk/util-defaults-mode-node": "3.85.0",
    "@aws-sdk/util-stream-browser": "3.78.0",
    "@aws-sdk/util-stream-node": "3.78.0",
    "@aws-sdk/util-user-agent-browser": "3.78.0",
    "@aws-sdk/util-user-agent-node": "3.80.0",
    "@aws-sdk/util-utf8-browser": "3.55.0",
    "@aws-sdk/util-utf8-node": "3.55.0",
    "@aws-sdk/util-waiter": "3.78.0",
    "@aws-sdk/xml-builder": "3.55.0",
    "entities": "2.2.0",
    "fast-xml-parser": "3.19.0",
    "tslib": "^2.3.1"
  },
  "devDependencies": {
    "@aws-sdk/service-client-documentation-generator": "3.58.0",
    "@tsconfig/recommended": "1.0.1",
    "@types/chai": "^4.2.11",
    "@types/mocha": "^8.0.4",
    "@types/node": "^12.7.5",
    "concurrently": "7.0.0",
    "downlevel-dts": "0.7.0",
    "rimraf": "3.0.2",
    "typedoc": "0.19.2",
    "typescript": "~4.6.2"
  },
  "engines": {
    "node": ">=12.0.0"
  },
  "typesVersions": {
    "<4.0": {
      "dist-types/*": [
        "dist-types/ts3.4/*"
      ]
    }
  },
  "files": [
    "dist-*"
  ],
  "author": {
    "name": "AWS SDK for JavaScript Team",
    "url": "https://aws.amazon.com/javascript/"
  },
  "license": "Apache-2.0",
  "browser": {
    "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.browser"
  },
  "react-native": {
    "./dist-es/runtimeConfig": "./dist-es/runtimeConfig.native"
  },
  "homepage": "https://github.com/aws/aws-sdk-js-v3/tree/main/clients/client-s3",
  "repository": {
    "type": "git",
    "url": "https://github.com/aws/aws-sdk-js-v3.git",
    "directory": "clients/client-s3"
  }
}
