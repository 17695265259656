import { DownloadOutlined } from "@ant-design/icons";
import {
    Button, Card, DatePicker,
    FilterDropdown,
    FilterDropdownProps, Input,
    List,
    NumberField,
    Popover,
    Table,
    TagField,
    TextField,
    UrlField,
    useTable
} from "@pankod/refine-antd";
import { usePermissions } from "@pankod/refine-core";
import axios from "axios";

import { ICheckout } from "interfaces";
import { split } from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { PopupBuyerContent } from "../../components/layout/buyer-popup";
import { exportToExcel } from "../../utils/common";
import { v4 as uuidv4 } from 'uuid';


const { RangePicker } = DatePicker;

export const CheckoutList: React.FC = () => {
    const { data: permissionsData } = usePermissions();
    const [showButton, setshowButton] = React.useState(false)
    const [cancelCheckoutButton, setCancelCheckoutButton] = React.useState(false)
    const [returnCheckoutButton, setReturnCheckoutButton] = React.useState(false)

    React.useEffect(() => {
        if (permissionsData && permissionsData.defaultRole === "manager") {
            setshowButton(true)
        }
    }, [permissionsData])

    const { tableProps, sorter } = useTable<ICheckout>({
        initialCurrent: 1,
        initialPageSize: 200,
        initialSorter: [
            {
                field: "updated_at",
                order: "desc",
            },
        ],
        metaData: {
            fields: [
                "checkout_id",
                "external_id",
                "extra_data",
                "credit_line_id",
                "created_at",
                "updated_at",
                "checkout_time",
                "term_days",
                "status",
                "amount",
                "checkout_buyer_cost",
                "checkout_owed_amount",
                "limit_date",
                "checkout_date",
                "insurance_tax_rate",
                "insurance_tax_amount",
                "insurance_rate",
                "insurance_amount",
                {
                    credit_line: [
                        "currency",
                        {
                            buyer: [
                                'name',
                                'extra_data',
                                'external_id',
                                {
                                    seller: ['seller_id', 'name', 'platform_id']
                                }
                            ],
                        }
                    ]
                },
                {
                    platform: [
                        "name",
                        "country"
                    ]
                }
            ]
        }
    });

    const gridStyle: React.CSSProperties = {
        width: '25%',
        textAlign: 'center',
        height: '8em',
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return (
        <List>
            <Button className={"exporter_button"} type="text" shape="circle" icon={<DownloadOutlined />} size={"middle"} onClick={() => exportToExcel(tableProps.dataSource as any[], new Map<string, string>([
                ["checkout_id", "Checkout Id"],
                ["credit_line_id", "Credit line Id"],
                ["external_id", "External Id"],
                ["created_at", "Created at"],
                ["updated_at", "Updated at"],
                ["credit_line.buyer.seller.name", "Seller"],
                ["credit_line.buyer.name", "Buyer"],
                ["credit_line.buyer.external_id", "Buyer External id"],
                ["checkout_date", "Checkout date"],
                ["checkout_time", "Checkout time"],
                ["term_days", "Term days"],
                ["status", "Status"],
                ["amount", "Amount"],
                ["checkout_buyer_cost", "checkout_buyer_cost"],
                ["checkout_owed_amount", "checkout_owed_amount"],
                ["insurance_rate", "insurance_rate"],
                ["insurance_amount", "insurance_amount"],
                ["insurance_tax_rate", "insurance_tax_rate"],
                ["insurance_tax_amount", "insurance_tax_amount"],
                ["credit_line.currency", "Currency"]
            ]), `BNPL_CheckoutList`)} />
            <Table {
                ...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ["bottomRight"],
                    size: "default",
                    pageSizeOptions: [50, 100, 200, 500, 1000, 5000, 10000]
                }}
                rowKey="checkout_id"
                expandable={{
                    expandedRowRender: record => {
                        const today = Date.parse(split(record.limit_date, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        // const today1 = Date.parse(split(record.checkout_date, "+")[0])
                        // const newValue1 = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today1)
                        return <div>
                            <Card.Grid style={gridStyle}>limit_date:<strong>  {newValue}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>checkout_date:<strong>  {record.checkout_date}</strong></Card.Grid>
                            <Card.Grid style={gridStyle}>external_id:<strong>  {record.credit_line.buyer?.external_id}</strong></Card.Grid>
                        </div>;

                    }
                }}
            >
                {showButton ? (
                    <Table.Column<ICheckout> dataIndex="checkout_id" title="Cancel?"
                        render={(value, record) => (
                            <Button type="primary" danger ghost loading={cancelCheckoutButton} onClick={() => {
                                Swal.fire({
                                    title: 'Are you sure?',
                                    text: 'You want to cancel the checkout ' + value + ' ($' + record.amount + ")",
                                    icon: 'warning',
                                    showDenyButton: true,
                                    confirmButtonText: 'Yes'
                                }).then((option) => {
                                    if (option.isConfirmed) {
                                        setCancelCheckoutButton(true)
                                        const config = {
                                            headers: {
                                                "partner_id": record.credit_line.buyer?.seller.platform_id,
                                                "Authorization": record.platform.country === "MX" ? localStorage.getItem("mxr2token") : localStorage.getItem("r2token"),
                                            }
                                        }
                                        let url = process.env.REACT_APP_BNPL_API_URL
                                        if (url === undefined) {
                                            url = ""
                                        }
                                        axios.delete(`${url}/checkout/cancel/${value}`, config).then((result) => {
                                            // add swal
                                            Swal.fire({
                                                title: 'Checkout CANCELED',
                                                text: 'Balance updated',
                                                icon: 'success'
                                            }).then(() => window.location.reload());
                                        }).catch((error) => {
                                            console.log(error);
                                            Swal.fire({
                                                title: 'NOT CANCELED',
                                                text: 'Ups, something went wrong, ' + error.response.data.error,
                                                icon: 'error'
                                            });
                                        }).finally(() => setCancelCheckoutButton(false));
                                    }
                                })
                            }}>
                                {"CANCEL"}
                            </Button>
                        )}
                    />
                )
                    :
                    null
                }
                {showButton ? (
                    <Table.Column<ICheckout> dataIndex="checkout_id" title="Create Payment Link?"
                        render={(value, record) => (
                            <Button type="primary" ghost onClick={async () => {
                                const { value: amountToSend } = await Swal.fire({
                                    title: 'Submit the amount please.',
                                    input: 'text',
                                    inputValue: record.checkout_owed_amount,
                                    showCancelButton: true,
                                    confirmButtonText: 'Done',
                                    showLoaderOnConfirm: true
                                })
                                if (amountToSend) {
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: 'You want to create a payment link to the checkout ' + value + ' (' + formatter.format(amountToSend) + ")",
                                        icon: 'warning',
                                        showDenyButton: true,
                                        confirmButtonText: 'Yes'
                                    }).then((option) => {
                                        if (option.isConfirmed) {
                                            const config = {
                                                headers: {
                                                    "partner_id": record.credit_line.buyer?.seller.platform_id,
                                                    "Authorization": record.platform.country === "MX" ? localStorage.getItem("mxr2token") : localStorage.getItem("r2token")
                                                }
                                            }
                                            let url = process.env.CREATE_PAYMENT_LINK_URL
                                            if (url === undefined) {
                                                url = ""
                                            }
                                            const data = {
                                                'checkout_id': value,
                                                "platform_id": record.credit_line.buyer?.seller.platform_id,
                                                "command": "CreatePaymentLink",
                                                "provider": record.platform.country === "MX" ? "KUSHKI" : "WOMPI",
                                                "amount": Number(amountToSend),
                                                "credit_line_id": record.credit_line_id,
                                                "description": "Compra en " + record.credit_line.buyer?.seller.name + " por " + record.credit_line.currency + " " + formatter.format(record.checkout_owed_amount)
                                            }
                                            axios.post(url, data, config).then((result) => {
                                                // add swal
                                                Swal.fire({
                                                    title: 'Payment link created',
                                                    input: 'text',
                                                    inputValue: result.data.items[0].provider_data,
                                                    inputAttributes: {
                                                        disabled: 'disabled'
                                                    },

                                                    icon: 'success',
                                                    confirmButtonText: 'Copy to clipboard'
                                                }).then((result) => {
                                                    if (result.value) {
                                                        navigator.clipboard.writeText(result.value).then(() => {
                                                            Swal.fire({
                                                                title: "Copied!",
                                                                text: "Text has been copied to clipboard.",
                                                                icon: "success"
                                                            });
                                                        }, (err) => {
                                                            Swal.fire({
                                                                title: "Error!",
                                                                text: "Failed to copy text.",
                                                                icon: "error"
                                                            });
                                                        });
                                                    }
                                                });
                                            }).catch((error) => {
                                                console.log("ERROR ", error);
                                                Swal.fire({
                                                    title: 'NOT CREATED',
                                                    text: 'Ups, something went wrong, ' + error,
                                                    icon: 'error'
                                                });
                                            });
                                        }
                                    })
                                }
                            }}>
                                {"GET PAYMENT LINK"}
                            </Button>
                        )}
                    />
                )
                    :
                    null
                }
                {showButton ? (
                    <Table.Column<ICheckout> dataIndex="checkout_id" title="Return?"
                        render={(value, record) => (
                            <Button type="primary" ghost loading={returnCheckoutButton} onClick={async () => {
                                const { value: amountToSend } = await Swal.fire({
                                    title: 'Submit the amount please.',
                                    input: 'text',
                                    inputValue: 0,
                                    showCancelButton: true,
                                    confirmButtonText: 'Done',
                                    showLoaderOnConfirm: true
                                })
                                if (amountToSend) {
                                    const amountParsed = Number(amountToSend)

                                    if (isNaN(amountParsed)) {
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'The amount format is not valid.',
                                            icon: 'error',
                                        })
                                        return;
                                    }

                                    if (amountParsed < 0) {
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'The amount entered is less than zero.',
                                            icon: 'error',
                                        })
                                        return;
                                    }

                                    if (amountParsed > record.amount) {
                                        Swal.fire({
                                            title: 'Error',
                                            text: 'The amount entered (' + amountToSend + ') is higher than the checkout amount (' + record.amount + ').',
                                            icon: 'error',
                                        })
                                        return;
                                    }

                                    setReturnCheckoutButton(true)
                                    Swal.fire({
                                        title: 'Are you sure?',
                                        text: 'You want to return to the checkout ' + value + ' (' + formatter.format(amountToSend) + ")",
                                        icon: 'warning',
                                        showDenyButton: true,
                                        confirmButtonText: 'Yes'
                                    }).then((option) => {
                                        if (option.isConfirmed) {
                                            const config = {
                                                headers: {
                                                    "partner_id": record.credit_line.buyer?.seller.platform_id,
                                                    "Authorization": record.platform.country === "MX" ? localStorage.getItem("mxr2token") : localStorage.getItem("r2token")
                                                }
                                            }
                                            let url = process.env.REACT_APP_BNPL_API_URL
                                            if (url === undefined) {
                                                url = ""
                                            }

                                            const data = {
                                                "related_checkout_id": value,
                                                "amount": amountParsed,
                                                "credit_line_id": record.credit_line_id,
                                                "external_id": uuidv4()
                                            }
                                            axios.post(url + '/checkout/return', data, config).then((result) => {
                                                // add swal
                                                Swal.fire({
                                                    title: 'Amount returned successfully',
                                                    icon: 'success',
                                                }).then(() => window.location.reload());
                                            }).catch((error) => {
                                                console.log("ERROR ", error);
                                                Swal.fire({
                                                    title: 'NOT CREATED',
                                                    text: 'Ups, something went wrong, ' + error.response.data.error,
                                                    icon: 'error'
                                                });
                                            }).finally(() => setReturnCheckoutButton(false));
                                        } else {
                                            setReturnCheckoutButton(false);
                                        }
                                    })
                                }
                            }}>
                                {"RETURN"}
                            </Button>
                        )}
                    />
                )
                    :
                    null
                }
                <Table.Column dataIndex="checkout_id" title="ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column dataIndex="credit_line_id" title="Credit line ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column dataIndex="external_id" title="External ID"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )} />
                <Table.Column
                    dataIndex="created_at"
                    title="createdAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="checkout_date"
                    title="checkout_date"
                    render={(value) => {
                        // const today = Date.parse(split(value, "+")[0])
                        // const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(today)
                        return (<p>{value}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="updated_at"
                    title="updatedAt"
                    render={(value) => {
                        const today = Date.parse(split(value, "+")[0])
                        const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                        return (<p>{newValue}</p>)
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICheckout>
                    dataIndex={["credit_line", "buyer", "seller", "name"]}
                    title="Seller"
                    render={(value, record) => {

                        const seller = record.credit_line?.buyer?.seller
                        return (
                            <Link to={`sellers/${seller?.seller_id}`}>
                                <UrlField
                                    value={seller?.name}
                                />
                            </Link>
                        );
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICheckout>
                    dataIndex={["credit_line", "buyer", "external_id"]}
                    title="Buyer"
                    render={(value, record) =>
                        <Popover
                            content={<PopupBuyerContent buyer={record.credit_line.buyer} />}
                            title="Buyer"
                        >
                            <TextField value={record.credit_line.buyer?.external_id} />
                        </Popover>
                    }
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="checkout_time"
                    title="CheckoutTime"
                    render={(value) => {
                        if (value) {
                            const today = Date.parse(split(value, "+")[0])
                            const newValue = new Intl.DateTimeFormat('es-MX', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(today)
                            return (<p>{newValue}</p>)
                        } else {
                            return (<p>Not informed</p>)
                        }
                    }}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <RangePicker format={"YYYY-MM-DD"} />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex="term_days"
                    title="TermDays"
                    render={(value) => <NumberField value={value} />}
                />
                <Table.Column
                    dataIndex="status"
                    title="Status"
                    render={(value) => <TagField value={value} />}
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column<ICheckout>
                    dataIndex={["credit_line", "currency"]}
                    title="Currency"
                    render={(value, record) => <TextField value={record.credit_line?.currency} />}
                />
                <Table.Column
                    dataIndex="amount"
                    title="Amount"
                    render={(value) => <NumberField
                        value={value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column
                    dataIndex="checkout_buyer_cost"
                    title="Buyer cost"
                    render={(value) => <NumberField
                        value={value}
                        options={{
                            style: 'currency',
                            currency: 'USD',
                            currencyDisplay: 'narrowSymbol',
                            maximumFractionDigits: 2,
                        }}
                    />}
                />
                <Table.Column
                    dataIndex="checkout_owed_amount"
                    title="Owed amount"
                    render={(value) => {
                        let newValue = 0.00
                        if (value) {
                            newValue = value
                        }
                        return (<NumberField
                            value={newValue}
                            options={{
                                style: 'currency',
                                currency: 'USD',
                                currencyDisplay: 'narrowSymbol',
                                maximumFractionDigits: 2,
                            }}
                        />)
                    }}
                />
                <Table.Column
                    dataIndex="insurance_amount"
                    title="insurance_amount"
                    render={(value) => {
                        if (value) {
                            return (<NumberField value={value} />)
                        } else {
                            return (<NumberField value={0} />)
                        }
                    }
                    }
                />
                <Table.Column
                    dataIndex="insurance_rate"
                    title="insurance_rate"
                    render={(value) => {
                        if (value) {
                            return (<NumberField value={value} />)
                        } else {
                            return (<NumberField value={0} />)
                        }
                    }}
                />
                <Table.Column
                    dataIndex="insurance_tax_amount"
                    title="insurance_tax_amount"
                    render={(value) => {
                        if (value) {
                            return (<NumberField value={value} />)
                        } else {
                            return (<NumberField value={0} />)
                        }
                    }}
                />
                <Table.Column
                    dataIndex="insurance_tax_rate"
                    title="insurance_tax_rate"
                    render={(value) => {
                        if (value) {
                            return (<NumberField value={value} />)
                        } else {
                            return (<NumberField value={0} />)
                        }
                    }}
                />
            </Table>
        </List >
    );
};