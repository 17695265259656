import {
    Create,
    Form,
    Input,
    Select,
    useForm,
    useSelect,
    useTable
} from "@pankod/refine-antd";
import { MaskedInput } from "antd-mask-input";
import axios from "axios";
import { ICheckout, ICollection, ICreditLine } from "interfaces";
import { useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';

export const CollectionCreate: React.FC = () => {
    const { formProps, saveButtonProps, queryResult, form, } =
        useForm<ICollection>({

        });

    const [line, setLine] = useState<string | undefined>(undefined)
    const [checkoutId, setCheckoutId] = useState<string | undefined>(undefined)
    const [formLoading, setFormLoading] = useState<boolean>(false)
    const { selectProps: linesProps } = useSelect<ICreditLine>({
        resource: "bnpl" + process.env.BNPL_STAGE + "_credit_line",
        metaData: {
            fields: [
                'credit_line_id',
                {
                    buyer: [
                        'buyer_id',
                        'external_id',
                        'extra_data'
                    ]
                },
                {
                    platform: [
                        'platform_id',
                    ]
                },
            ]
        },
        sort: [
            {
                field: 'credit_line_id',
                order: 'desc'
            }
        ],
        onSearch: (value) => [
            {
                field: "credit_line_id",
                operator: "eq",
                value,
            },
        ],
        // filters: [
        //     {
        //         field: 'state',
        //         operator: 'eq',
        //         value: 'active'
        //     }
        // ],
        // defaultValue: queryResult?.data?.data?.,
        optionLabel: "credit_line_id",
        optionValue: "credit_line_id",
    });

    const { tableProps: creditLineData } = useTable<ICreditLine>({
        resource: "bnpl" + process.env.BNPL_STAGE + "_credit_line",
        permanentFilter: [
            {
                field: "credit_line_id",
                operator: "eq",
                value: line
            }
        ],
        metaData: {
            fields: [
                {
                    platform: [
                        'platform_id',
                        'country',
                    ]
                }
            ]
        }
    });

    console.log("QUER ", creditLineData)

    const onLineSelect = (event: string) => {
        // console.log(event)
        setLine(event)
    }

    const onFinish = (values: { [x: string]: any; }) => {
        console.log("ON ", values);
        // let key = "credit_line_id";
        // delete values[key];
        values["amount"] = parseFloat(values["amount"]);
        values["external_id"] = uuidv4();

        const partner_id = creditLineData.dataSource[0].platform.platform_id
        let authKey = creditLineData.dataSource[0].platform.country === "MX" ? localStorage.getItem("mxr2token") : localStorage.getItem("r2token")
        if (partner_id === "5b0837a8-6cf1-473e-8d66-77489de63db4") {
            authKey = localStorage.getItem("mxkaltor2token")
        }

        const config = {
            headers: {
                "Authorization": authKey,
                "partner_id": partner_id
            }
        }

        setFormLoading(true)

        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to create a new collection',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Yes'
        }).then((option) => {
            if (option.isConfirmed) {
                axios.post(process.env.REACT_APP_BNPL_API_URL + "/collection", values, config).then((result) => {
                    console.log("Recibo ", result);
                    // add swal
                    Swal.fire({
                        title: 'Collection added',
                        text: 'Collections were created ok !',
                        icon: 'success'
                    });
                }).catch((error) => {
                    console.log(error);
                    Swal.fire({
                        title: 'Collection not added',
                        text: 'Ups, something is wrong, ' + error,
                        icon: 'error'
                    });
                }).finally(() => { setFormLoading(false) });
            }
        });

        //formProps.onFinish!({ ...values, collection_time: (new Date()).toISOString(), collection_date: (new Date()).toISOString().split('T')[0], collection_type: "DIRECT", status:'VALID' })
    }

    return (
        <Create saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <Form {...formProps}
                onFinish={onFinish}
                initialValues={{
                    // collected_at: (new Date()).toISOString()
                    observations: ""
                }}
                layout="vertical"
            >
                <Form.Item label="Credit Line" name="credit_line_id" rules={[
                    {
                        required: true,
                        message: 'The credit line is required.',
                    }
                ]}>
                    <Select {...linesProps} onChange={onLineSelect} />
                </Form.Item>
                <Form.Item label="Collection type" name="collection_type" rules={[
                    {
                        required: true,
                        message: 'The collection type is required.',
                    }
                ]}>
                    <Select
                        options={[
                            {
                                label: "Direct",
                                value: "DIRECT",
                            },
                            {
                                label: "Seller",
                                value: "SELLER",
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Amount" name="amount" rules={[
                    {
                        required: true,
                        message: 'The amount is required.',
                    }
                ]}
                ><MaskedInput mask={"0[0][0][0][0][0][0][0][0][0][0][0][.][0][0]"} placeholder=''></MaskedInput></Form.Item>
                <Form.Item label="Observations" name="observations">
                    <Input type="text" placeholder={""} />
                </Form.Item>
            </Form>
        </Create>
    );
};