import { ButtonHTMLAttributes, DetailedHTMLProps, SVGProps, useEffect, useState } from "react";
import axios from 'axios';

import { logger } from "../../utils/common";
import Swal from "sweetalert2";

interface BelvoWidgetProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    onboarding_id: string,
    token: string | null
}

function LoadIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>)
}

export const BelvoWidget = (props: BelvoWidgetProps) => {
    useScript('https://cdn.belvo.io/belvo-widget-1-stable.js');
    const { widgetState, createWidget } = useBelvo()

    return <>
        <div id="belvo">
        </div>
        <fieldset className="form-control w-full">
            <h2 className="section-label">Análisis de flujos de caja (opcional)</h2>
            <div className='flex flex-col md:flex-row items-center mx-4'>
                <label htmlFor="" className='font-bold md:mr-4'>
                    Necesitamos conocer tus finanzas para poder aprobarte una línea de crédito
                    acorde a tus capacidades, este será un proceso muy sencillo donde analizaremos
                    tus flujos de caja y determinaremos un monto apropiado:
                </label>
                {/* {widgetState} */}
                <button
                    className="py-4 px-16 bg-primary text-white font-bold my-6 rounded-md flex w-full items-center"
                    onClick={() => createWidget(props.onboarding_id, props.token)}>
                    {widgetState === 'idle' && "Analizar datos con Belvo"}
                    {widgetState === 'exit' && "Volver a analizar datos con Belvo"}
                    {widgetState === 'error' && 'Algun Error :/'}
                    {widgetState === 'done' && 'Hecho!'}
                    {((widgetState === 'creating') || (widgetState === 'access-token')) && <>
                        <LoadIcon className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" />
                        Cargando widget...
                    </>}
                    {widgetState === 'created' && <>
                        <LoadIcon className="animate-spin -ml-1 mr-3 h-6 w-6 text-white" />
                        Esperando respuesta...
                    </>}
                </button>
            </div>
        </fieldset>
        {/* <script async type="text/javascript" src="https://cdn.belvo.io/belvo-widget-1-stable.js" /> */}
    </>
}

function useScript(src: string) {
    useEffect(
        () => {
            // Create script
            const node = document.createElement('script');
            node.src = src;
            node.type = 'text/javascript';
            node.async = true;
            // node.onload = useCreateWidget
            // Add script to document body
            document.body.appendChild(node);
        },
        [src] // Only re-run effect if script src changes
    )
}


export type StateType = 'idle' | 'access-token' | 'creating' | 'created' | 'error' | 'exit' | 'done'

// Insert the following code after useScript() function from Step 1.
export function useBelvo() {
    const [widgetState, setWidgetState] = useState<StateType>('idle')
    const [belvoData, setBelvoData] = useState<Record<string, any>>({})
    const ORIGIN = "onboarding_r2_form"

    useEffect(() => {
        const sendData = async () => {
            await axios.post(process.env.REACT_APP_BELVO_URL!, {
                link_id: belvoData.link,
                onboarding_id: belvoData.onboarding_id,
                token: belvoData.token,
                origin: ORIGIN
            },
            )
                .then((data) => {
                    console.log("success ", data)
                    Swal.fire({
                        title: '¡ Gracias !',
                        text: 'Con esta información, vamos a darte una experiencia personalizada, luego estaremos informandote como continúa el proceso.',
                        icon: 'success',
                        showCancelButton: false,
                    })
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Oops, error',
                        text: 'Hemos detectado algún error, por favor comunicate con nosotros para poder analizar el caso. ' + error,
                        icon: 'error',
                        showCancelButton: false,
                    })
                });
        }
        const sendAudit = async () => {
            await axios.post(process.env.REACT_APP_BELVO_URL! + '/audit', belvoData)
                .catch((error) => {
                    Swal.fire({
                        title: 'Oops, error',
                        text: 'Hemos detectado algún error, por favor comunicate con nosotros para poder analizar el caso. ' + error + ' ' + JSON.stringify(belvoData),
                        icon: 'error',
                        showCancelButton: false,
                    })
                });
        }
        if (belvoData.link) {
            sendData()
        }
        if (belvoData.eventName) {
            belvoData.origin = ORIGIN
            sendAudit()
        }

    }, [belvoData])

    const createWidget = async (onboarding_id: string, token: string | null) => {    // Function to call your server-side to generate the access_token and retrieve the your access token
        async function getAccessToken() {
            // Make sure to change /get-access-token to point to your server-side.
            const res = await axios.get(process.env.REACT_APP_BELVO_AUTH_URL!)
            return res.data
        }

        const successCallbackFunction = (link: string, institution: string) => {
            setWidgetState('done')
            logger.log(link, institution)
            setBelvoData({
                link,
                institution,
                onboarding_id,
                token
            })

            // Do something with the link and institution,
            // such as associate it with your registered user in your database.
        }
        const onExitCallbackFunction = (data: Record<string, any>) => {
            setWidgetState('exit')
            data.onboarding_id = onboarding_id
            data.token = token
            logger.log(data)
            setBelvoData(data)
            // Do something with the exit data.
        }
        const onEventCallbackFunction = (data: Record<string, any>) => {
            data.onboarding_id = onboarding_id
            data.token = token
            setBelvoData(data)
            logger.log(data)
            // Do something with the event data.
        }
        const config = {
            // Add your startup configuration here.
            access_mode: "single",
            external_id: onboarding_id,
            country_codes: ["MX"],
            // institutions: ["tatooine_mx_fiscal"],
            institution_types: ["fiscal"],
            locale: "es",
            callback: successCallbackFunction,
            onExit: onExitCallbackFunction,
            onEvent: onEventCallbackFunction
        }
        try {
            setWidgetState('access-token')
            const { access } = await getAccessToken();
            setWidgetState('creating')
            window.belvoSDK.createWidget(access, config).build()
            setWidgetState('created')
        }
        catch (error) {
            console.error('Error:', error)
            setWidgetState('error')
        }
    }

    return {
        createWidget,
        widgetState,
        belvoData,
    }
}

let useBelvoContainer: any
export function _useBelvo() {
    if (!useBelvoContainer) {
        useBelvoContainer = _useBelvo()
    }
    return useBelvoContainer
}