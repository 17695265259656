import { ChangeEvent, HTMLAttributes, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";
import { FileUpload, SelectCredential } from "./components";
import { IPersonaForm } from "./types";
import { validationPatterns } from "./validations";

export interface FisicaFormProps extends HTMLAttributes<HTMLDivElement> {

}

export const FisicaForm = (props: FisicaFormProps) => {
    const { register, setValue, watch, formState: { errors } } = useFormContext<IPersonaForm>();
    const watchRFC = watch('rfc')

    const validateRfc = () => {
        if (!validationPatterns.rfc_fisica.test(watchRFC)) {
            Swal.fire({
                title: "El RFC ingresado no es válido",
                text:"Por favor valida que hayas ingresado correctamente tu RFC",
                showCancelButton: false,
                showDenyButton: false,
            })
        }
    }

    useEffect(() => {
        if (watchRFC) {
            normalizeRfc(watchRFC)
        }
    }, [watchRFC])
    

    const normalizeRfc = (e : String) => {
        const normalRFC = e.replaceAll(" ", "").toUpperCase()
        setValue('rfc',normalRFC)
    }

    // console.log(errors)
    return <div {...props}>
        <fieldset>
            <h2 className="section-label">Ingresa los siguientes datos acerca de tu identidad y actividad:</h2>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="RFC (Registro Federal de Contribuyentes)"
                    className="input input-bordered w-full"
                    {...register("rfc", {
                        required: true,
                        pattern: {
                            value: validationPatterns.rfc_fisica,
                            message: 'el RFC ingresado debe ser válido',
                        }
                    })}
                    onBlur={validateRfc}
                />
                {errors.rfc && <span className="text-red-600">{errors.rfc?.message}</span>}
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Nombre y apellidos"
                    className="input input-bordered w-full"
                    {...register("name", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="email"
                    placeholder="Email de contacto"
                    className="input input-bordered w-full"
                    {...register("email", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="phone"
                    placeholder="Número de Whatsapp: ej. +52 55 7194 8630"
                    className="input input-bordered w-full"
                    {...register("phone", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Actividad, giro, ocupación o profesión"
                    className="input input-bordered w-full"
                    {...register("activity", { required: true })}
                />
            </div>
            <div className="form-control w-full">
                <input
                    type="text"
                    placeholder="Nombre del comercio"
                    className="input input-bordered w-full"
                    {...register("commercial_name", { required: true })}
                />
            </div>
        </fieldset>
        <fieldset>
            <div className="form-control w-full">
                <label className="label label-text" htmlFor="domicilio">Adjuntar Comprobante de Domicilio (Legible y no mayor a 3 meses):</label>
                <FileUpload name='domicilio' filenamePrefix='comprobante_domicilio' optional='false'/>
            </div>
        </fieldset>
        <SelectCredential />
        <fieldset>
            <div className="form-control w-full">
                <label className="label label-text" htmlFor="num_employees">Cantidad de empleados:</label>
                <select
                    className="input input-bordered w-full bg-white"
                    {...register("employees_qty_range", { valueAsNumber: true })}
                >
                    <option value="0">--SELECCIONAR--</option>
                    <option value="1">1-5</option>
                    <option value="2">5-15</option>
                    <option value="3">15-50</option>
                    <option value="4">mas 50</option>
                </select>
            </div>
            <div className="form-control w-full">
                <label className="label label-text" htmlFor="old_time">Tiempo De Operación / Antigüedad (opcional):</label>
                <select
                    className="input input-bordered w-full bg-white"
                    {...register("commercial_oper_range", { valueAsNumber: true })}
                >
                    <option value="1">menor a 6 meses</option>
                    <option value="2">entre 6 meses a 1 año</option>
                    <option value="3">entre 1 año a 2 años</option>
                    <option value="4">mas de 2 años</option>
                </select>
            </div>
            <div className="form-control w-full">
                <input
                    type="url"
                    placeholder="Página web o redes sociales (opcional)"
                    className="input input-bordered w-full"
                    {...register("webpage")}
                />
            </div>
        </fieldset>
        {/* <h2 className="section-label">Otros documentos:</h2> */}
    </div>
}