import { AccessControlProvider, AuthProvider } from "@pankod/refine-core";
import { newEnforcer } from "casbin.js";

import { model, adapter } from "./accessControl";


export const accessProvider: (authProvider: AuthProvider) => AccessControlProvider = (authProvider) => ({
    can: async ({ resource, action }) => {
        const enforcer = await newEnforcer(model, adapter);

        const perms = await authProvider.getPermissions()
        // console.log(perms.defaultRole, perms)
        const can = await enforcer.enforce(
            perms.defaultRole,
            resource,
            action,
        );

        return Promise.resolve({ can });
    },
})