import React from "react";
import routerProvider from "@pankod/refine-react-router";
import { TitleProps } from "@pankod/refine-core";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={new URL('r2.svg', import.meta.url)}
        alt="R2"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px 24px",
        }}
      />
    ) : (
      <img
        src={new URL('r2.svg', import.meta.url)}
        alt="R2"
        className="w-24 m-auto my-5"
      />
    )}
  </Link>
);
